import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ThemeProvider } from '@mui/styles';
import { FrontendMaterialTheme } from '../Theme';
import { StyledEngineProvider, Typography } from '@mui/material';
import Emoji from 'react-emoji-render';

export interface VerseViewerProps {
  bookName: string;
  chapterNumber: number;
  verseText: string;
  verseNumber: number;
}

export const VerseViewer = (props: VerseViewerProps) => {
  const emojiEnabled = false;
  let content: JSX.Element = <>{props.verseText}</>;
  if (emojiEnabled) {
    content = <Emoji text={props.verseText} />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontendMaterialTheme}>
        <Card variant='outlined'>
          <CardContent>
            <Typography
              sx={{
                fontSize: 36,
                textAlign: 'center',
                letterSpacing: '6px',
              }}
              color='textSecondary'
              gutterBottom>
              {props.bookName} {props.chapterNumber}:{props.verseNumber}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              sx={{
                fontSize: 32,
                textAlign: 'center',
              }}>
              “{content}”
              <br />
              <Typography
                variant='caption'
                sx={{
                  fontSize: 16,
                  color: '#333',
                  textAlign: 'center',
                }}>
                King James Version (KJV)
              </Typography>
            </Typography>
          </CardContent>
        </Card>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
