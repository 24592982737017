import * as env from 'env-var';

// Constants
export const COOKIE_NAME = env.get('SCRIBE_COOKIE_NAME').default('scribe-tsbc-com').asString();
// This is in SECONDS
//const MAX_SESSION = 60;
// two hours
export const MAX_SESSION = env
  .get('SCRIBE_MAX_SESSION')
  .default(2 * 60 * 60)
  .asInt();

export const UserRoles = {
  Admin: {
    ID: 0,
    Name: 'Admin',
  },
  Editor: {
    ID: 1,
    Name: 'Editor',
  },
  Contributor: {
    ID: 2,
    Name: 'Contributor',
  },
};
