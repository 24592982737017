import React from 'react';
import { ThemeProvider } from '@mui/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import ShareIcon from '@mui/icons-material/Share';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Tooltip from '@mui/material/Tooltip';
import blueGrey from '@mui/material/colors/blueGrey';
import copy from 'copy-to-clipboard';

import { FrontendMaterialTheme } from './Theme';
import { FormatMemory, FormatOriginal } from './Utils';
import { SummaryStyle } from './styles';
import Box from '@mui/material/Box';

export interface SummaryProps {
  verseText: string;
  memoryOfVerse: string;
  notes: string;
  BCV: string;
  changeID: number;
  detectedAt: string;
}

export const Summary = (props: SummaryProps) => {
  const classes = SummaryStyle();
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  let memoryOfVerseFormatted = FormatMemory(props.verseText, props.memoryOfVerse);
  let currentVerseFormatted = FormatOriginal(props.verseText, props.memoryOfVerse);

  if (props.memoryOfVerse === '') {
    memoryOfVerseFormatted = (
      <Box component='span' sx={{ color: 'green !important' }}>
        Note: No memory of this verse has been submitted.
      </Box>
    );
    currentVerseFormatted = <Box component='span'>{props.verseText}</Box>;
  }

  const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const getClipboardDataForDiscord = (): string => {
    return `${window.location.origin}/changeDetail/${props.changeID}`;
  };

  const copyToClipBoardForDiscordAction = () => {
    copy(getClipboardDataForDiscord());
    setAlertMessage('Detail Link Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const getClipboardDataForShare = (): string => {
    return `${window.location.origin}/changes/${props.changeID}`;
  };

  const copyToClipBoardForShareAction = () => {
    copy(getClipboardDataForShare());
    setAlertMessage('Change Link Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontendMaterialTheme}>
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image='img/FB_YO_Creation_JPEGW/08_FB_YO_Creation_1920_cropped.jpg'
            title='Genesis'
          />
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Summary of {props.BCV}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography className={classes.caption} gutterBottom variant='caption'>
                    Memory of Verse
                  </Typography>
                  <Typography className={classes.verse} color='textPrimary' gutterBottom>
                    {memoryOfVerseFormatted}
                  </Typography>
                </Paper>
                <Paper className={classes.paper}>
                  <Typography className={classes.caption} gutterBottom variant='caption'>
                    Current Verse
                  </Typography>
                  <Typography className={classes.verse} color='textPrimary' gutterBottom>
                    {currentVerseFormatted}
                  </Typography>
                </Paper>
                {props.detectedAt !== '' && (
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'large' }} color='info' gutterBottom variant='caption'>
                      Detected (approximately): {props.detectedAt}
                    </Typography>
                  </Paper>
                )}
              </Grid>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography className={classes.caption} gutterBottom variant='caption'>
                    Notes
                  </Typography>
                  <Typography className={classes.verse} color='textPrimary' gutterBottom>
                    {props.notes}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', backgroundColor: blueGrey.A400 }}>
            <Paper variant='elevation' sx={{ color: 'blue' }}>
              <Tooltip title='Detail Link'>
                <IconButton
                  aria-label='summary-detail-copy-link'
                  onClick={() => {
                    copyToClipBoardForDiscordAction();
                  }}>
                  <IosShareOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Change Link'>
                <IconButton
                  aria-label='summary-change-copy-link'
                  onClick={() => {
                    copyToClipBoardForShareAction();
                  }}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Paper>
          </CardActions>
        </Card>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} variant='filled' severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
