import React from 'react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import Tooltip from '@mui/material/Tooltip';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Legend } from './Legend';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
});

export const LegendPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <ThemeProvider theme={customTheme}>
      <Tooltip title='Show Legend'>
        <IconButton
          aria-describedby={id}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          color='inherit'
          onClick={handleClick}>
          <LegendToggleIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Legend />
      </Popover>
    </ThemeProvider>
  );
};
