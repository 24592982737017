import React, { useState, useEffect, useRef } from 'react';

import MaterialTable, { Query } from 'material-table';
import { StyledScrollUp } from '../common/StyledScrollUp';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Summary } from './Summary';
import { ChangeTableTitle, TitleSummaryProps } from './ChangeTableTitle';
import { ResidueViewerDetailed } from './ResidueViewerDetailed';
import { MemorySummary } from './MemorySummary';
import {
  GetChangesByRange,
  GetChangesByRangeWithSearch,
  GetChangesByRangeIncludingID,
  GetChangesByRangeStartingAtBCV,
  GetDBMetrics,
} from '../../common/Api';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';

import { Helmet } from 'react-helmet';
import HealthCheck from './HealthCheck';

//import SimulatedChangeData from "../../test_data/SimulatedChangeData";

export interface ChangeTableProps {
  viewChange?: number;
}

interface DBMetrics {
  memory_count: number;
  change_count: number;
  image_count: number;
}

interface BCVData {
  bookNumber: number;
  chapter: number;
  verse: number;
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
});

export const ChangeTable = (props: ChangeTableProps) => {
  const [pageSize, setPageSize] = useState(5);
  const [isDown, setIsDown] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteringEnabled, setFilteringEnabled] = useState(false);
  const [DBMetrics, setDBMetrics] = useState<DBMetrics>({
    memory_count: 0,
    change_count: 0,
    image_count: 0,
  });
  const [currentBCV, setCurrentBCV] = useState<BCVData>({
    bookNumber: 1,
    chapter: 1,
    verse: 1,
  });
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [internalBCVSelectionChanged, setInternalBCVSelectionChanged] = useState(false);
  const [internalViewChangeID, setInternalViewChangeID] = useState(props?.viewChange);

  const tableRef = useRef<any>();

  const bcvSelectorChanged = (bookNumber: number, chapter: number, verse: number) => {
    //console.log(`ChangeTable: BCV initiated search by range BCV ${bookNumber} ${chapter}:${verse}`);
    setCurrentBCV({ bookNumber: bookNumber, chapter: chapter, verse: verse });
    setInternalBCVSelectionChanged(true);
    tableRef.current.onQueryChange();
  };

  const titleData: TitleSummaryProps = {
    memoryCount: DBMetrics.memory_count,
    changeCount: DBMetrics.change_count,
    imageCount: DBMetrics.image_count,
    videoCount: 0,
    urlCount: 0,
    data: null,
    titleText: 'Changes',
    bcvSelectorChanged: bcvSelectorChanged,
  };

  const detailPanel = [
    (rowData: any) => ({
      icon: () => {
        // TODO: need to received "has residue" from API to distinguish icons here
        if (rowData?.memorySummary.restoredText !== '') {
          // residue and memory
          return <ErrorOutlineOutlinedIcon fontSize='large' color='error' />;
        }
        if (rowData?.memorySummary.notes !== '') {
          return <WarningAmberOutlinedIcon fontSize='large' color='info' />;
        }
        if (rowData?.MemoriesOfVerse?.length > 0) {
          return <ErrorOutlineOutlinedIcon fontSize='large' color='action' />;
        }
        return <ErrorOutlineOutlinedIcon fontSize='large' color='disabled' />;
      },
      tooltip: rowData?.memorySummary.restoredText !== '' ? 'Show Memory and Notes' : 'Show Notes',
      render: () => {
        let notes = rowData.notes;
        if (notes.length === 0) {
          // iterate over memories and find the first notes
          for (let i = 0; i < rowData.MemoriesOfVerse.length; i++) {
            if (rowData.MemoriesOfVerse[i].notes.length > 0) {
              notes = rowData.MemoriesOfVerse[i].notes;
              break;
            }
          }
        }
        return (
          <Summary
            BCV={rowData.BCV}
            memoryOfVerse={rowData.memorySummary.restoredText}
            notes={notes}
            verseText={rowData.currentVerse}
            changeID={rowData.ID}
            detectedAt={rowData.detectedAt}
          />
        );
      },
    }),
    (rowData: any) => ({
      icon: () => {
        if (rowData.hasResidue) {
          //console.log("residue exists");
          // residue exists
          return <PhotoLibraryOutlinedIcon fontSize='large' color='secondary' />;
        }
        //console.log("residue does not exist");
        return <NoteOutlinedIcon fontSize='large' color='disabled' />;
      },
      tooltip: !rowData.hasResidue ? 'No Residue' : 'Show Residue',
      render: () => {
        if (rowData.hasResidue) {
          return <ResidueViewerDetailed data={rowData} chapterViewerProps={props} />;
        }
      },
    }),
  ];

  useEffect(() => {
    function sleep(ms: any) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const CheckServerAlive = async () => {
      let keepChecking = true;
      while (keepChecking) {
        await sleep(5000);
        const dbMetrics = await GetDBMetrics();
        if (dbMetrics) {
          console.log('Server is up!');
          keepChecking = false;
          setIsDown(false);
        }
      }
    };

    if (isDown) {
      CheckServerAlive();
    }
  }, [isDown]);

  const handleFetchData = async (query: Query<any>) => {
    //console.log(JSON.stringify(query));
    if (query.search) {
      //console.log(`fetchData search ${query.search}`);
      const result = await GetChangesByRangeWithSearch(query.page, query.pageSize, query.search);
      return result;
    } else {
      try {
        const dbMetrics = await GetDBMetrics();
        if (dbMetrics) {
          setDBMetrics(dbMetrics);
        } else {
          console.log('Server down...?');
          setIsDown(true);
        }
        if (internalBCVSelectionChanged) {
          setInternalBCVSelectionChanged(false);
          const result = await GetChangesByRangeStartingAtBCV(
            query.page,
            query.pageSize,
            currentBCV.bookNumber,
            currentBCV.chapter,
            currentBCV.verse
          );
          if (result) {
            setIsDown(false);
            return result;
          }
        }
        if (internalViewChangeID) {
          const result = await GetChangesByRangeIncludingID(query.page, query.pageSize, internalViewChangeID);
          if (result) {
            setIsDown(false);
            // clear it so we can page normally
            setInternalViewChangeID(0);
            return result;
          }
        }
        const result = await GetChangesByRange(query.page, query.pageSize);
        if (result) {
          setIsDown(false);
          return result;
        } else {
          console.log('Server down...!');
          setIsDown(true);
        }
      } catch (e: any) {
        console.log('Server down...');
        console.log(e);
      }
    }
  };

  const title = <ChangeTableTitle data={titleData} />;
  //console.log("inside changeTable!");
  // need to switch to this
  // https://material-table.com/#/docs/features/remote-data
  return (
    <>
      <Helmet>
        <title>Scribe: View Changes</title>
        <meta name='description' content='View Verse Changes.' />
        <meta name='og:title' content='Scribe: View Changes' />
        <meta name='og:image' content='img/bible_changes.jpg' />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <HealthCheck isDown={isDown} />
          {!isDown && (
            <MaterialTable
              /*
          components={{
            Pagination: props => (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <ChangeTableTitle data={titleData} />
                </Grid>
                <Grid item xs={4}>
                <MTablePagination {...props}/>
                </Grid>
              </Grid>
            )
          }}
          */
              /*
            components={{
              Toolbar: props => (<MTableToolbar
                        {...props}
                        onSearchChanged={(searchText: string) => {
                          console.log(`TB onSearchChange searchText = ${searchText}`);

                            // do, what you want
                            //props.onSearchChanged(searchText);
                        }}
                    />)
            }}
            */
              tableRef={tableRef}
              style={{
                backgroundColor: '#607d8b',
                display: 'flex',
                flexDirection: 'column',
              }}
              //onSearchChange={(searchText) => {
              //  console.log(`onSearchChange searchText = ${searchText}`);
              //}}
              onChangeRowsPerPage={(size) => {
                setPageSize(size);
              }}
              onRowClick={(evt, selectedRow, togglePanel) => {
                setSelectedRow(selectedRow);
              }}
              localization={{
                pagination: {
                  labelRowsPerPage: '',
                },
              }}
              options={{
                debounceInterval: 2000,
                filtering: filteringEnabled,
                padding: 'dense',
                paginationPosition: 'both',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 30, 40, 50, 100],
                search: true,
                searchFieldAlignment: 'right',
                sorting: true,
                headerStyle: {
                  backgroundColor: '#01579b', // #3299eb
                  color: '#FFFFFF',
                },
                rowStyle: (rowData) => ({
                  verticalAlign: 'top',
                  backgroundColor: selectedRow?.tableData?.id === rowData.tableData.id ? '#EEEEEE' : '#FFFFFF',
                }),
                filterCellStyle: {
                  padding: '0px',
                },
              }}
              actions={
                [
                  //{
                  //  icon: 'refresh',
                  //  tooltip: 'Refresh Data',
                  //  isFreeAction: true,
                  //  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                  //},
                  //{
                  //  icon: 'filter_list',
                  //  tooltip: 'Toggle Filters',
                  //  isFreeAction: true,
                  //  onClick: () => {setFilteringEnabled(!filteringEnabled)},
                  //},
                ]
              }
              columns={[
                {
                  title: 'ID',
                  field: 'ID',
                  width: 'auto',
                },
                {
                  title: 'B/C/V',
                  field: 'BCV',
                  customSort: (a: any, b: any) => {
                    // TODO: this still appears to be bugged
                    let aVal = a.book * 10000 + a.chapter * 100 + a.verse;
                    let bVal = b.book * 10000 + b.chapter * 100 + b.verse;
                    return aVal - bVal;
                  },
                  width: 'auto',
                  headerStyle: { whiteSpace: 'nowrap' },
                  cellStyle: { whiteSpace: 'nowrap' },
                },
                {
                  title: 'Current',
                  field: 'currentVerse',
                  width: 'auto',
                },
                {
                  title: 'Memories',
                  field: 'memorySummaries',
                  width: 'auto',
                  render: (rowData) => <MemorySummary data={rowData} />,
                },
              ]}
              data={handleFetchData}
              title={title}
              detailPanel={detailPanel}
            />
          )}
          <StyledScrollUp
            startPosition={0}
            showAtPosition={10}
            position='right'
            className='scroll-up'
            style={{
              left: '90px',
            }}>
            <Button variant='contained' size='large' color='primary'>
              <ArrowUpwardIcon color='action' />
            </Button>
          </StyledScrollUp>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
