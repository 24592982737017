import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import blueGrey from '@mui/material/colors/blueGrey';

export const backendMaterialTheme = createTheme({
  palette: {
    primary: blueGrey,
  },
});

export const backendStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    card: {
      width: 600,
      alignContent: 'center',
      backgroundColor: 'grey',
      margin: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    chapterVerse: {
      width: 100,
    },
    chapterVerseGrid: {
      marginTop: 20,
    },
    counter: {
      width: 120,
      marginLeft: 12,
      marginTop: 20,
    },
    changeNumberTitle: {
      fontSize: 14,
    },
    changeNumberChip: {
      alignContent: 'center',
    },
  })
);

export const changeEditorSimpleStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export const adminStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export const changeDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: 'center',
    },
    dialogPaper: {
      minHeight: '35vh',
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  })
);

export const memoryDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: 'center',
    },
    dialogPaper: {
      minHeight: '35vh',
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  })
);

export const residueDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: 'center',
    },
    dialogPaper: {
      minHeight: '35vh',
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  })
);

export const AddUserDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: 'center',
    },
    dialogPaper: {
      minHeight: '35vh',
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  })
);

export const adminMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  })
);
