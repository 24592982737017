import { makeStyles } from '@mui/styles';

const searchResultStyles = makeStyles(() => ({
  loading: {
    fontSize: 16,
  },
  matchedText: {
    backgroundColor: 'yellow',
    fontWeight: 'bold',
  },
}));

export { searchResultStyles };
