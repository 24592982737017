import React from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  ChangeProps,
  AddChangeDialogProps,
  EditChangeDialogProps,
  AddMemoryDialogProps,
  EditMemoryDialogProps,
  AddResidueDialogProps,
  EditResidueDialogProps,
} from './types';
import { TextField } from '@mui/material';

export interface BackendDatePickerProps {
  editorSettings:
    | ChangeProps
    | AddChangeDialogProps
    | EditChangeDialogProps
    | AddMemoryDialogProps
    | EditMemoryDialogProps
    | AddResidueDialogProps
    | EditResidueDialogProps;
  setEditorSettings: any;
}
export const BackendDatePicker = (props: BackendDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        renderInput={(params) => <TextField {...params} />}
        value={props.editorSettings.selectedDate}
        label='Approximately when?'
        onChange={(val) => {
          if (val === null) {
            val = new Date();
          }
          //props.editorSettings.selectedDate = val;
          props.setEditorSettings({ ...props.editorSettings });
        }}
      />
    </LocalizationProvider>
  );
};
