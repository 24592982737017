import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import { ThemeProvider } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { adminMaterialTheme } from './Theme';
import { ResidueImage } from '../common/ResidueImage';
import { GetResidueOfMemory, DeleteResidueLookup } from '../../common/Api';
import { adminStyles } from './Styles';
import { AddResidueDialog } from './AddResidueDialog';
import { EditResidueDialog } from './EditResidueDialog';

interface ResidueTableRowData {
  ID: any;
  date: any;
  imageURL: string;
  imagePath: string;
  notes: string;
}

export function ResidueTable(props: any) {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  const [residueSelected, setResidueSelected] = React.useState<ResidueTableRowData | null>(null);
  const [data, setData] = React.useState<ResidueTableRowData[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [deleteResidueDisabled, setDeleteResidueDisabled] = React.useState(true);
  const [editResidueDisabled, setEditResidueDisabled] = React.useState(true);
  const [showAddResidueDialog, setShowAddResidueDialog] = React.useState(false);
  const [showEditResidueDialog, setShowEditResidueDialog] = React.useState(false);

  const classes = adminStyles();

  const handleRowClick = async (event: any, aSelectedRow: any) => {
    setResidueSelected(aSelectedRow);
    setDeleteResidueDisabled(false);
    setEditResidueDisabled(false);
  };

  const fetchData = async (id: string, signal: AbortSignal) => {
    if (props.id === null) {
      setIsLoading(false);

      return;
    }
    await GetResidueOfMemory(id, signal).then((response) => {
      if (response) {
        // TODO: verify this is json
        setData(response.residue);
      }
    });
    setIsLoading(false);
  };
  // load residue by id
  useEffect(() => {
    if (isLoading) {
      const abortController = new AbortController();
      const signal = abortController.signal;
      fetchData(props.id, signal);
      // TODO: this is not working and is disabled in the fetch
      return function cleanup() {
        abortController.abort();
      };
    }
  });

  const onAddResidueDialogClosed = async () => {
    setShowAddResidueDialog(false);
    // reload
    setShowAlert(false);
    setIsLoading(true);
  };
  const onEditResidueDialogClosed = async () => {
    setShowEditResidueDialog(false);
    // reload
    setShowAlert(false);
    setIsLoading(true);
  };

  const onAddResidue = async () => {
    setShowAddResidueDialog(true);
  };
  const onEditResidue = async () => {
    setShowEditResidueDialog(true);
  };
  const onDeleteResidue = async () => {
    if (residueSelected === null) {
      return;
    }
    setDeleteResidueDisabled(true);
    const payload = {
      id: 0,
      residueImageID: residueSelected.ID,
      memoryID: props.id,
    };
    const data = await DeleteResidueLookup(payload);
    // if there are no more links  the residue itself will be deleted by the backend
    // set alert with status of save
    setAlertMessage('Delete Status: ' + data.message);
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const ResidueOptions = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography className={classes.instructions}>
          Database has {data.length} residue items for this memory.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => {
            onAddResidue();
          }}>
          Add Residue
        </Button>
        <Button
          disabled={editResidueDisabled}
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => {
            onEditResidue();
          }}>
          Edit Residue
        </Button>
        <Button
          disabled={deleteResidueDisabled}
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => {
            onDeleteResidue();
          }}>
          Delete Residue
        </Button>
      </div>
    );
  };

  return (
    <ThemeProvider theme={adminMaterialTheme}>
      <MaterialTable
        style={{ margin: '20px' }}
        onRowClick={handleRowClick}
        options={{
          padding: 'dense',
          paging: false,
          toolbar: false,
          search: false,
          headerStyle: {
            backgroundColor: 'rgb(9, 123, 189)',
            color: '#FFFFFF',
          },
          rowStyle: (rowData) => ({
            backgroundColor: residueSelected && residueSelected.ID === rowData.ID ? 'palegreen' : '#FFF',
          }),
        }}
        columns={[
          {
            title: 'ID',
            field: 'ID',
            width: 'auto',
          },
          {
            title: 'Residue Date',
            field: 'imageDate',
            width: 'auto',
          },
          {
            title: 'URL',
            field: 'imageURL',
            width: 'auto',
          },
          {
            title: 'Path',
            field: 'imagePath',
            width: 'auto',
          },
          {
            title: 'Notes',
            field: 'notes',
            width: 'auto',
          },
        ]}
        data={data}
        detailPanel={[
          {
            icon: 'image',
            tooltip: 'Show Image',
            render: (rowData) => {
              return <ResidueImage data={rowData} />;
            },
          },
        ]}
      />
      <ResidueOptions />
      {showAddResidueDialog && (
        <AddResidueDialog
          onAddResidueDialogClosed={onAddResidueDialogClosed}
          bookNumber={props.bookNumber}
          chapterNumber={props.chapterNumber}
          verseNumber={props.verseNumber}
          memoryID={props.id}
          memoryData={props.memoryData}
        />
      )}
      {showEditResidueDialog && (
        <EditResidueDialog
          onEditResidueDialogClosed={onEditResidueDialogClosed}
          residueID={residueSelected?.ID}
          selectedDate={residueSelected?.date}
          notes={residueSelected?.notes}
          imagePath={residueSelected?.imagePath}
          imageURL={residueSelected?.imageURL}
        />
      )}
      <Snackbar open={showAlert} autoHideDuration={5000} onClose={onAddResidueDialogClosed}>
        <Alert
          variant='filled'
          severity={alertSeverity}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                onAddResidueDialogClosed();
              }}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }>
          {alertMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

/*
{editorSettings.showEditMemoryDialog &&
  <EditResidueDialog
    onEditMemoryDialogClosed={editResidueDialogClosed}
    bookNumber={props.bookNumber}
    chapterNumber={props.chapter}
    verseNumber={props.verse}
    memoryData={props.memorySelected}
/>}
*/
