import React, { Component, useState } from 'react';
import * as env from 'env-var';
import { ThemeProvider } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import NumericInput from 'react-numeric-input';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { changeTypes, ChangeProps } from './types';
import { backendMaterialTheme, backendStyles } from './Styles';
import {
  GetChangeIDs,
  GetChanges,
  GetBookNames,
  GetVerseText,
  DeleteChange,
  UpdateChange,
  AddChange,
  GetChaptersOfBook,
} from '../../common/Api';
import { BackendDatePicker } from './BackendDatePicker';
import { BCVSelect } from './BCVSelect';
import { GetCachedChangedDataItem, GetBookNumber } from './Utils';

const Styled = ({ props }: any) => {
  const onChangeBook = async (event: any) => {
    if (event) {
      const bookName = event.target.textContent;
      editorSettings.bookName = bookName;
      const b = GetBookNumber(editorSettings.bookNames, bookName);
      editorSettings.bookNumber = b;
      editorSettings.inputBookValue = bookName;
      let response = await GetChaptersOfBook(editorSettings.bookNumber);
      editorSettings.bookMaxChapters = response.chapters;
      setEditorSettings({ ...editorSettings });
      // reset to chapter 1 to stay in range
      onChangeChapter(1);
    }
  };

  const onChangeChapter = (value: any) => {
    if (value > 0) {
      editorSettings.chapter = value;
      setEditorSettings({ ...editorSettings });
      onChapterSelectChanged(value);
      // reset to verse 1 to stay in range
      onChangeVerse(1);
    }
  };

  const onChangeVerse = async (value: any) => {
    if (value > 0) {
      editorSettings.verse = value;
      setEditorSettings({ ...editorSettings });
      onVerseSelectChanged(value);
      // load changes for this verse
      const changeIDs = await GetChangeIDs(editorSettings.bookNumber, editorSettings.chapter, value);
      setExistingChangeIDs(changeIDs);
      if (changeIDs && changeIDs.length > 0) {
        editorSettings.selectedChangeID = changeIDs[0];
        const changeData = await GetChanges(editorSettings.bookNumber, editorSettings.chapter, value);
        if (changeData) {
          editorSettings.selectedChange = changeData[0];
          setEditorSettings({ ...editorSettings });
          // update cache
          setChangeCache(changeData);
        }
      } else {
        editorSettings.selectedChangeID = null;
        editorSettings.selectedChange = null;
        setEditorSettings({ ...editorSettings });
        // invalidate cache
        setChangeCache(null);
      }
    }
  };

  const initProps: ChangeProps = {
    bookName: 'Genesis',
    bookNames: props.data,
    bookNumber: 1,
    bookMaxChapters: 50,
    inputBookValue: 'Genesis',
    chapter: 1,
    verse: 1,
    selectedChangeID: null,
    selectedChange: null,
    inputExistingChangeValue: '',
    changeType: 'Added',
    inputChangeTypeValue: '',
    source: '',
    keywords: '',
    notes: '',
    wordCount: 0,
    isMissing: false,
    isHeresy: false,
    isMeaningChange: false,
    isDoctrineChange: false,
    hasFlipFlops: false,
    isPunctuationError: false,
    punctuationErrors: 0,
    isDuplicate: false,
    isNew: false,
    isCapitalizationError: false,
    isHonorificError: false,
    isGrammarError: false,
    grammarErrors: 0,
    changeNumber: 'TBD',
    selectedDate: new Date(),
    selectedVerseText: props.verseText,
    onChangeChapter: onChangeChapter,
    onChangeVerse: onChangeVerse,
    onChangeBook: onChangeBook,
  };
  const [editorSettings, setEditorSettings] = React.useState<ChangeProps>(initProps);
  const [changeCache, setChangeCache] = useState(props.changeData);
  const [existingChangeIDs, setExistingChangeIDs] = useState(props.changes);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('info');
  const classes = backendStyles();

  const onChangeWordCount = (value: any) => {
    if (value >= 0) {
      editorSettings.wordCount = value;
      setEditorSettings({ ...editorSettings });
    }
  };
  const onChangePunctuationErrors = (value: any) => {
    if (value > 0) {
      editorSettings.punctuationErrors = value;
      setEditorSettings({ ...editorSettings });
    }
  };
  const onChangeGrammarErrors = (value: any) => {
    if (value > 0) {
      editorSettings.grammarErrors = value;
      setEditorSettings({ ...editorSettings });
    }
  };

  const onClear = async () => {
    editorSettings.chapter = 1;
    editorSettings.verse = 1;
    editorSettings.hasFlipFlops = false;
    editorSettings.changeNumber = 'TBD';
    editorSettings.isCapitalizationError = false;
    editorSettings.isDoctrineChange = false;
    editorSettings.isDuplicate = false;
    editorSettings.isGrammarError = false;
    editorSettings.grammarErrors = 0;
    editorSettings.isHeresy = false;
    editorSettings.isHonorificError = false;
    editorSettings.isMeaningChange = false;
    editorSettings.isMissing = false;
    editorSettings.isNew = false;
    editorSettings.isPunctuationError = false;
    editorSettings.punctuationErrors = 0;
    editorSettings.keywords = '';
    editorSettings.notes = '';
    editorSettings.source = '';
    editorSettings.wordCount = 0;
    editorSettings.changeType = 'Other';
    editorSettings.selectedDate = new Date();
    editorSettings.bookName = 'Genesis';
    editorSettings.bookNumber = 1;
    editorSettings.inputBookValue = 'Genesis';
    setEditorSettings({ ...editorSettings });
    // TODO: fix verse preview
  };

  const onDelete = async () => {
    const payload = {
      id: parseInt(editorSettings.changeNumber, 10),
    };
    const data = await DeleteChange(payload);
    //console.log(data.message);
    //console.log(data.status);
    // set alert with status of save
    setAlertMessage('Delete Status: ' + data.message);
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const onRefresh = async () => {
    loadExistingChange(editorSettings.selectedChangeID);
    setAlertSeverity('success');
    setAlertMessage('Refreshed!');
    setShowAlert(true);
  };

  /**
   * fetch change by id and populates fields
   *
   * @param value change number
   */
  const loadExistingChange = async (value: any) => {
    editorSettings.selectedChangeID = value;
    setEditorSettings({ ...editorSettings });
    // need to set selectedChange to be the content of the cached item
    //console.log(changeCache);
    let changeCacheIndex = GetCachedChangedDataItem(changeCache, value);
    if (changeCacheIndex >= 0) {
      editorSettings.selectedChange = changeCache[changeCacheIndex];
      setEditorSettings({ ...editorSettings });
    }
    //console.log('Load!');
    if (value && editorSettings.selectedChange) {
      // only preload once
      //setPreload(false);
      // populate the form
      editorSettings.hasFlipFlops = editorSettings.selectedChange.hasFlipflops;
      editorSettings.changeNumber = editorSettings.selectedChange.ID.toString();
      editorSettings.isCapitalizationError = editorSettings.selectedChange.capitalizationGeneral;
      editorSettings.isDoctrineChange = editorSettings.selectedChange.isDoctrineChange;
      editorSettings.isDuplicate = editorSettings.selectedChange.isDuplicate;
      editorSettings.isGrammarError = editorSettings.selectedChange.hasGrammarErrors;
      editorSettings.grammarErrors = editorSettings.selectedChange.grammarErrorCount;
      editorSettings.isHeresy = editorSettings.selectedChange.isHeresy;
      editorSettings.isHonorificError = editorSettings.selectedChange.capitalizationHonorifics;
      editorSettings.isMeaningChange = editorSettings.selectedChange.isMeaningChange;
      editorSettings.isMissing = editorSettings.selectedChange.isMissing;
      editorSettings.isNew = editorSettings.selectedChange.isNew;
      editorSettings.isPunctuationError = editorSettings.selectedChange.hasPunctuationErrors;
      editorSettings.punctuationErrors = editorSettings.selectedChange.punctuationErrorCount;
      editorSettings.keywords = editorSettings.selectedChange.keywords;
      editorSettings.notes = editorSettings.selectedChange.notes;
      editorSettings.source = editorSettings.selectedChange.source;
      editorSettings.wordCount = editorSettings.selectedChange.wordChangeCount;
      editorSettings.selectedDate = editorSettings.selectedChange.CreatedAt;
      setEditorSettings({ ...editorSettings });
      //setChangeType(selectedChange.changeType); TODO: fix this in the database
    }
  };

  const onSave = async () => {
    // convert name of book to number
    const payload = {
      detectedAt: editorSettings.selectedDate,
      source: editorSettings.source,
      notes: editorSettings.notes,
      changeType: editorSettings.changeType,
      keywords: editorSettings.keywords,
      isMissing: editorSettings.isMissing,
      isHeresy: editorSettings.isHeresy,
      isMeaningChange: editorSettings.isMeaningChange,
      isDoctrineChange: editorSettings.isDoctrineChange,
      hasFlipflops: editorSettings.hasFlipFlops,
      isDuplicate: editorSettings.isDuplicate,
      isNew: editorSettings.isNew,
      capitalizationGeneral: editorSettings.isCapitalizationError,
      capitalizationHonorifics: editorSettings.isHonorificError,
      hasPunctuationErrors: editorSettings.isPunctuationError,
      hasGrammarErrors: editorSettings.isGrammarError,
      wordChangeCount: editorSettings.wordCount,
      grammarErrorCount: editorSettings.grammarErrors,
      punctuationErrorCount: editorSettings.punctuationErrors,
      upVotes: 0,
      downVotes: 0,
      book: editorSettings.bookNumber,
      chapter: editorSettings.chapter,
      verse: editorSettings.verse,
      ID: parseInt(editorSettings.changeNumber, 10) || 0,
    };
    var changeData;
    // post the change, and return the ID with confirmation dialog
    if (editorSettings.changeNumber === 'TBD') {
      payload.ID = 0;
      changeData = await AddChange(payload);
    } else {
      changeData = await UpdateChange(payload);
    }
    changeData = await AddChange(payload);
    if (changeData.hasOwnProperty('id')) {
      editorSettings.changeNumber = changeData.id;
    }
    setEditorSettings({ ...editorSettings });
    setAlertMessage('Save Status: ' + changeData.message);
    setShowAlert(true);
  };

  const onVerseSelectChanged = async (verse: any) => {
    const response = await GetVerseText(editorSettings.bookNumber, editorSettings.chapter, editorSettings.verse);
    editorSettings.selectedVerseText = response.text;
    setEditorSettings({ ...editorSettings });
  };

  const onChapterSelectChanged = async (chapter: any) => {
    const response = await GetVerseText(editorSettings.bookNumber, editorSettings.chapter, editorSettings.verse);
    editorSettings.selectedVerseText = response.text;
    setEditorSettings({ ...editorSettings });
  };

  return (
    <form className={classes.root} noValidate autoComplete='on'>
      <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
        <BackendDatePicker editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
        <Tooltip title='Change being edited or to be determined when new'>
          <Chip size='medium' label={'Change Number:' + editorSettings.changeNumber} />
        </Tooltip>
        <Autocomplete
          id='existingChange'
          style={{ width: 150 }}
          value={editorSettings.selectedChangeID}
          onChange={(event: any, newValue: string | null) => {
            loadExistingChange(newValue);
          }}
          inputValue={editorSettings.inputExistingChangeValue}
          onInputChange={(event, newInputValue) => {
            editorSettings.inputExistingChangeValue = newInputValue;
            setEditorSettings({ ...editorSettings });
          }}
          options={existingChangeIDs}
          renderInput={(params) => <TextField {...params} required label='Existing Changes' margin='dense' />}
        />
      </Grid>

      <BCVSelect editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
          <Autocomplete
            id='change_type'
            value={editorSettings.changeType}
            style={{ width: 150 }}
            onChange={(event: any, newValue: string | null) => {
              editorSettings.changeType = newValue;
              setEditorSettings({ ...editorSettings });
            }}
            inputValue={editorSettings.inputChangeTypeValue}
            onInputChange={(event, newInputValue) => {
              editorSettings.inputChangeTypeValue = newInputValue;
              setEditorSettings({ ...editorSettings });
            }}
            options={changeTypes}
            renderInput={(params) => <TextField {...params} required label='Change Type' margin='dense' />}
          />
          <TextField
            style={{ width: '100%' }}
            margin='dense'
            value={editorSettings.keywords}
            id='keywords'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorSettings.keywords = event.target.value;
              setEditorSettings({ ...editorSettings });
            }}
            label='Keywords'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ width: '100%' }}
            margin='dense'
            value={editorSettings.source}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorSettings.source = event.target.value;
              setEditorSettings({ ...editorSettings });
            }}
            id='source'
            label='Source'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            label='Word Change Count'
            labelPlacement='start'
            control={
              <NumericInput
                mobile
                id='wordChangeCount'
                className={classes.chapterVerse}
                aria-label='word-change-count'
                inputMode='numeric'
                min={0}
                max={10000}
                value={editorSettings.wordCount}
                onChange={onChangeWordCount}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
        <TextField
          style={{ width: '100%' }}
          id='outlined-multiline-static'
          label='Notes'
          value={editorSettings.notes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editorSettings.notes = event.target.value;
            setEditorSettings({ ...editorSettings });
          }}
          multiline
          rows='8'
          variant='outlined'
        />
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <FormControlLabel
            label='Is Missing?'
            control={
              <Switch
                checked={editorSettings.isMissing}
                onChange={(event) => {
                  editorSettings.isMissing = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isMissing'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Is Heresy?'
            control={
              <Switch
                checked={editorSettings.isHeresy}
                onChange={(event) => {
                  editorSettings.isHeresy = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isHeresy'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Is Meaning Change?'
            control={
              <Switch
                checked={editorSettings.isMeaningChange}
                onChange={(event) => {
                  editorSettings.isMeaningChange = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isMeaningChange'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Is Doctrine Change?'
            control={
              <Switch
                checked={editorSettings.isDoctrineChange}
                onChange={(event) => {
                  editorSettings.isDoctrineChange = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isDoctrineChange'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Has Flipflops?'
            control={
              <Switch
                checked={editorSettings.hasFlipFlops}
                onChange={(event) => {
                  editorSettings.hasFlipFlops = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='hasFlipFlops'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Is Duplicate?'
            control={
              <Switch
                checked={editorSettings.isDuplicate}
                onChange={(event) => {
                  editorSettings.isDuplicate = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isDuplicate'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Is New?'
            control={
              <Switch
                checked={editorSettings.isNew}
                onChange={(event) => {
                  editorSettings.isNew = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isNew'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Capitalization Error?'
            control={
              <Switch
                checked={editorSettings.isCapitalizationError}
                onChange={(event) => {
                  editorSettings.isCapitalizationError = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isCapitalizationError'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            label='Honorific Capitalization Error?'
            control={
              <Switch
                checked={editorSettings.isHonorificError}
                onChange={(event) => {
                  editorSettings.isHonorificError = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isHonorificError'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <FormControlLabel
            label='Punctuation Error?'
            control={
              <Switch
                checked={editorSettings.isPunctuationError}
                onChange={(event) => {
                  editorSettings.isPunctuationError = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isPunctuationError'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            disabled={!editorSettings.isPunctuationError}
            label='Punctuation Error Count'
            labelPlacement='start'
            control={
              <NumericInput
                mobile
                id='punctuationErrorCount'
                className={classes.chapterVerse}
                aria-label='punctuationErrorCount'
                inputMode='numeric'
                min={0}
                max={100}
                value={editorSettings.punctuationErrors}
                onChange={onChangePunctuationErrors}
              />
            }
          />
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <FormControlLabel
            label='Grammar Error?'
            control={
              <Switch
                checked={editorSettings.isGrammarError}
                onChange={(event) => {
                  editorSettings.isGrammarError = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='isGrammarError'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
          <FormControlLabel
            disabled={!editorSettings.isGrammarError}
            label='Grammar Error Count'
            labelPlacement='start'
            control={
              <NumericInput
                mobile
                id='grammarErrorCount'
                className={classes.chapterVerse}
                aria-label='Grammar Error Count'
                inputMode='numeric'
                min={0}
                max={100}
                value={editorSettings.grammarErrors}
                onChange={onChangeGrammarErrors}
              />
            }
          />
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.button}
            startIcon={<ClearAllIcon />}
            onClick={() => {
              onClear();
            }}>
            New
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={() => {
              onSave();
            }}>
            Save
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={() => {
              onDelete();
            }}>
            Delete
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.button}
            startIcon={<RefreshIcon />}
            onClick={() => {
              onRefresh();
            }}>
            Refresh
          </Button>
        </Grid>
      </Grid>
      {showAlert && (
        <Alert variant='filled' severity={alertSeverity}>
          {alertMessage}
        </Alert>
      )}
    </form>
  );
};

class ChangeEditor extends Component {
  state: any;
  urlPrefix = '';

  constructor(props: any) {
    super(props);
    let isDevMode = false;
    if (env.get('NODE_ENV').default('production').asString() === 'development') {
      isDevMode = true;
    }
    if (isDevMode) {
      this.urlPrefix = `http://localhost:7777`;
    }

    this.state = {
      data: [{}],
      changes: [{}],
      verseText: '',
      isLoading: true,
    };
  }

  async initialize() {
    await GetBookNames().then((response) => {
      this.setState({
        data: response.names,
      });
    });
    await GetChangeIDs(1, 1, 1).then((response) => {
      this.setState({
        changes: response,
      });
    });
    await GetChanges(1, 1, 1).then((response) => {
      this.setState({
        changeData: response,
      });
    });
    await GetVerseText(1, 1, 1).then((response) => {
      this.setState({
        verseText: response.text,
      });
    });
  }

  componentDidMount() {
    this.initialize().then((response) => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    return (
      <ThemeProvider theme={backendMaterialTheme}>
        {this.state.isLoading && <i className='fa fa-spinner fa-spin'></i>}
        {!this.state.isLoading && <Styled props={this.state} />}
      </ThemeProvider>
    );
  }
}

export default ChangeEditor;
