import React, { useEffect, useState } from 'react';
import { StyledScrollUp } from '../../common/StyledScrollUp';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { VerseViewer } from './VerseViewer';
import { ContextViewer } from './ContextViewer';
import { ChapterViewer } from './ChapterViewer';
//import { ChapterViewerNext } from './ChapterViewerNext';
import HealthCheck from '../HealthCheck';
import { GetDBMetrics } from '../../../common/Api';

import {
  GetChapterTextWithChanges,
  GetBookNames,
  GetVerseText,
  GetChapterText,
  GetChapterVerses,
  GetChaptersOfBook,
} from '../../../common/Api';
import { BibleReaderMaterialTheme } from './Theme';

import { ReaderProps } from '../types';
import { BCVSelect } from '../../common/BCVSelect';

export interface BibleReaderProps {
  searchID: number;
  bookName: string;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
}

export const BibleReader = (props: BibleReaderProps) => {
  const [isDown, setIsDown] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const getBookNumber = (name: string) => {
    let b = 1;
    for (let i = 0; i < readerSettings.bookNames.length; i++) {
      if (name.toLowerCase() === readerSettings.bookNames[i].toLowerCase()) {
        b = i + 1;
        break;
      }
    }
    return b;
  };

  const onChangeBook = async (event: any) => {
    if (event) {
      const bookName = event.target.textContent;
      readerSettings.book = bookName;
      const b = getBookNumber(bookName);
      readerSettings.bookNumber = b;
      readerSettings.inputBookValue = bookName;
      let response = await GetChaptersOfBook(readerSettings.bookNumber);
      if (!response) {
        setIsDown(true);
        return;
      }
      readerSettings.bookMaxChapters = response.chapters;
      setReaderSettings({ ...readerSettings });
      // reset to chapter 1 to stay in range
      onChangeChapter(1);
    }
  };

  const onChangeChapter = (value: any) => {
    if (value > 0) {
      readerSettings.chapter = value;
      setReaderSettings({ ...readerSettings });
      onChapterSelectChanged(value);
      // reset to verse 1 to stay in range
      onChangeVerse(1);
    }
  };

  const onChangeVerse = async (value: any) => {
    if (value > 0) {
      readerSettings.verse = value;
      setReaderSettings({ ...readerSettings });
      onVerseSelectChanged(value);
    }
  };

  const onVerseSelectChanged = async (verse: any) => {
    const response = await GetVerseText(readerSettings.bookNumber, readerSettings.chapter, readerSettings.verse);
    if (!response) {
      setIsDown(true);
      return;
    }
    if (response?.text) {
      readerSettings.selectedVerseText = response.text;
      setReaderSettings({ ...readerSettings });
    }
  };

  const onChapterSelectChanged = async (chapter: any) => {
    let response = await GetChapterVerses(readerSettings.bookNumber, chapter);
    if (!response) {
      setIsDown(true);
      return;
    }
    readerSettings.chapterMaxVerses = response.verses;
    readerSettings.chapterText = await GetChapterText(readerSettings.bookNumber, chapter);
    readerSettings.chapterTextWithChanges = await GetChapterTextWithChanges(readerSettings.bookNumber, chapter);
    response = await GetVerseText(readerSettings.bookNumber, chapter, readerSettings.verse);
    if (!response) {
      setIsDown(true);
      return;
    }
    if (response?.text) {
      readerSettings.selectedVerseText = response.text;
    }
    setReaderSettings({ ...readerSettings });
  };
  const [searchID, setSearchID] = useState(0);

  const initProps: ReaderProps = {
    book: props.bookName,
    bookNames: [],
    bookNumber: props.bookNumber,
    chapter: props.chapterNumber,
    verse: props.verseNumber,
    inputBookValue: props.bookName,
    selectedVerseText: '',
    chapterText: [],
    chapterTextWithChanges: [],
    chapterMaxVerses: 0,
    bookMaxChapters: 0,
    onChangeChapter: onChangeChapter,
    onChangeVerse: onChangeVerse,
    onChangeBook: onChangeBook,
    searchProcessed: false,
    searchProcessedBook: '',
  };

  const [readerSettings, setReaderSettings] = React.useState<ReaderProps>(initProps);

  useEffect(() => {
    function sleep(ms: any) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const CheckServerAlive = async () => {
      let keepChecking = true;
      while (keepChecking) {
        const dbMetrics = await GetDBMetrics();
        if (dbMetrics) {
          console.log('Server is up!');
          keepChecking = false;
          setIsDown(false);
          setIsLoading(true);
        }
        await sleep(5000);
      }
    };
    if (isDown) {
      CheckServerAlive();
    }
  }, [isDown, isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      let flaggedAsDown = false;
      if (readerSettings.bookNames.length === 0) {
        const response = await GetBookNames();
        if (response?.names) {
          readerSettings.bookNames = response.names;
        } else {
          console.log('server not responding');
          flaggedAsDown = true;
        }
      }
      const responseVT = await GetVerseText(readerSettings.bookNumber, readerSettings.chapter, readerSettings.verse);
      if (responseVT) {
        readerSettings.selectedVerseText = responseVT.text;
      } else {
        console.log('server not responding');
        flaggedAsDown = true;
      }
      let responseCV = await GetChapterVerses(readerSettings.bookNumber, readerSettings.chapter);
      if (responseCV) {
        // @ts-ignore
        readerSettings.chapterMaxVerses = responseCV.verses;
      } else {
        console.log('server not responding');
        flaggedAsDown = true;
      }
      const responseCB = await GetChaptersOfBook(readerSettings.bookNumber);
      if (responseCB) {
        // @ts-ignore
        readerSettings.bookMaxChapters = responseCV.chapters;
      } else {
        console.log('server not responding');
        flaggedAsDown = true;
      }
      const responseChapterText = await GetChapterText(readerSettings.bookNumber, readerSettings.chapter);
      if (responseChapterText) {
        readerSettings.chapterText = responseChapterText;
      } else {
        console.log('server not responding');
        flaggedAsDown = true;
      }
      const responseChapterTextWithChange = await GetChapterTextWithChanges(
        readerSettings.bookNumber,
        readerSettings.chapter
      );
      if (responseChapterTextWithChange) {
        readerSettings.chapterTextWithChanges = responseChapterTextWithChange;
      } else {
        console.log('server not responding');
        flaggedAsDown = true;
      }
      setReaderSettings(readerSettings);
      setIsLoading(false);
      if (flaggedAsDown) {
        setIsDown(true);
      }
    };
    if (!isDown && isLoading) {
      fetchData();
    }

    //console.log(`searchID = ${searchID} props.searchID = ${props.searchID}`);
    //console.log(`book ${readerSettings.book} chapter ${readerSettings.chapter} verse ${readerSettings.verse}`);
    if (!readerSettings.searchProcessed && searchID !== props.searchID) {
      let needsProcessing = false;
      if (readerSettings.book !== props.bookName) {
        readerSettings.book = props.bookName;
        readerSettings.bookNumber = props.bookNumber;
        readerSettings.inputBookValue = props.bookName;
        needsProcessing = true;
      }
      if (readerSettings.chapter !== props.chapterNumber) {
        readerSettings.chapter = props.chapterNumber;
        needsProcessing = true;
      }
      if (readerSettings.verse !== props.verseNumber) {
        readerSettings.verse = props.verseNumber;
        needsProcessing = true;
      }
      if (needsProcessing) {
        readerSettings.searchProcessed = true;
        readerSettings.searchProcessedBook = props.bookName;
        setSearchID(props.searchID);
        setReaderSettings(readerSettings);
        setIsLoading(true);
      }
    }
  }, [isDown, isLoading, readerSettings, props, searchID]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={BibleReaderMaterialTheme}>
        <CssBaseline />
        <HealthCheck isDown={isDown} />
        {!isLoading && !isDown && (
          <Container>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
              <Grid item>
                <BCVSelect editorSettings={readerSettings} setEditorSettings={setReaderSettings} />
              </Grid>
            </Grid>
            <VerseViewer
              bookName={readerSettings.book || ''}
              chapterNumber={readerSettings.chapter}
              verseNumber={readerSettings.verse}
              verseText={readerSettings.selectedVerseText}
            />
            <ContextViewer
              bookName={readerSettings.book || ''}
              chapterNumber={readerSettings.chapter}
              verseNumber={readerSettings.verse}
              contextText={readerSettings.chapterText}
            />
            <ChapterViewer
              bookNumber={readerSettings.bookNumber}
              bookName={readerSettings.book || ''}
              chapterNumber={readerSettings.chapter}
              chapterText={readerSettings.chapterTextWithChanges}
            />
            <StyledScrollUp startPosition={0} showAtPosition={10} position='left'>
              <Button variant='contained' size='large' color='primary'>
                <ArrowUpwardIcon color='action' />
              </Button>
            </StyledScrollUp>
          </Container>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
