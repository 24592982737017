import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal',
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
  })
);

type ResidueImageProps = {
  data: any;
};

export function ResidueImage(props: ResidueImageProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={props.data.imageURL} title='Residue' />
          <CardContent>
            <Typography gutterBottom component='h2'>
              Created: {props.data.created}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.data.notes}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
