import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MemoryProps } from './types';
import { TextField } from '@mui/material';

export interface MemoryEditorDatePickerProps {
  editorSettings: MemoryProps;
  setEditorSettings: any;
}
export const MemoryEditorDatePicker = (props: MemoryEditorDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        value={props.editorSettings.selectedDate}
        renderInput={(params) => <TextField {...params} />}
        label='Approximately when?'
        onChange={(val) => {
          if (val === null) {
            val = new Date();
          }
          //props.editorSettings.selectedDate = val;
          props.setEditorSettings(props.editorSettings);
        }}
      />
    </LocalizationProvider>
  );
};
