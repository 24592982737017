import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ThemeProvider } from '@mui/styles';
import { FrontendMaterialTheme } from './Theme';
import { residueVerseViewerStyles } from './styles';
import { Typography } from '@mui/material';

export interface VerseViewerProps {
  bookName: string;
  chapterNumber: number;
  verseText: string;
  verseNumber: number;
}

export const ResidueVerseViewer = (props: VerseViewerProps) => {
  const classes = residueVerseViewerStyles();

  return (
    <ThemeProvider theme={FrontendMaterialTheme}>
      <Card className={classes.verseViewer} variant='outlined'>
        <CardContent>
          <Typography className={classes.verseViewerTitle} color='textSecondary' gutterBottom>
            {props.bookName} {props.chapterNumber}:{props.verseNumber}
          </Typography>
          <Typography variant='body2' component='p' className={classes.verseViewerText}>
            “{props.verseText}”
          </Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};
