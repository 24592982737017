import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import NumericInput from 'react-numeric-input';
import TextField from '@mui/material/TextField';
import { frontendStyles } from '../frontend/styles';
import { ReaderProps } from '../frontend/types';
import { AdminProps } from '../backend/types';
import { ChangesBCVSelectorProps } from '../frontend/types';

export interface BCVSelectProps {
  editorSettings: ReaderProps | AdminProps | ChangesBCVSelectorProps;
  setEditorSettings: any;
  chapterSelectionDisabled?: boolean;
  verseSelectionDisabled?: boolean;
}

export const BCVSelect = (props: BCVSelectProps) => {
  const classes = frontendStyles();
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid item>
        <Autocomplete
          size='small'
          id='book'
          sx={{ minWidth: 220 }}
          disableClearable={true}
          value={props.editorSettings.book}
          onChange={props.editorSettings.onChangeBook}
          inputValue={props.editorSettings.inputBookValue}
          onInputChange={(event, newInputValue) => {
            props.editorSettings.inputBookValue = newInputValue;
            props.setEditorSettings(props.editorSettings);
          }}
          options={props.editorSettings.bookNames}
          renderInput={(params) => (
            <TextField {...params} required label='Book' size='small' margin='dense' variant='outlined' />
          )}
        />
      </Grid>
      {!props.chapterSelectionDisabled && (
        <Grid item className={classes.chapterVerseGrid}>
          <FormControlLabel
            className={classes.chapterNumericInput}
            label='Chapter'
            labelPlacement='start'
            control={
              <NumericInput
                mobile
                id='chapter'
                className={classes.chapterVerse}
                aria-label='chapter'
                inputMode='numeric'
                min={1}
                max={props.editorSettings.bookMaxChapters}
                value={props.editorSettings.chapter}
                onChange={props.editorSettings.onChangeChapter}
              />
            }
          />
        </Grid>
      )}
      {!props.verseSelectionDisabled && (
        <Grid item className={classes.chapterVerseGrid}>
          <FormControlLabel
            label='Verse'
            labelPlacement='start'
            control={
              <NumericInput
                mobile
                id='verse'
                className={classes.chapterVerse}
                aria-label='verse'
                inputMode='numeric'
                min={1}
                max={props.editorSettings.chapterMaxVerses}
                value={props.editorSettings.verse}
                onChange={props.editorSettings.onChangeVerse}
              />
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
