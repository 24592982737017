import React, { useState, useEffect, useCallback } from 'react';
import * as env from 'env-var';
import { useParams, useLocation } from 'react-router-dom';

import './App.css';
import Cookies from 'universal-cookie';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';

// scribe
import { COOKIE_NAME, MAX_SESSION } from './common/Types';
import AdminMenu from './components/backend/AdminMenu';
import { appStyles, appMaterialTheme } from './Styles';
import { BibleReader, BibleReaderProps } from './components/frontend/BibleReader/BibleReader';
import { ChangeTable } from './components/frontend/ChangeTable';
import { About } from './components/frontend/About';
import { GetTokenFromCookie, GetBookNames } from './common/Api';
import { GetBookNumber } from './components/backend/Utils';
import LoginDialog from './components/frontend/Authentication/LoginDialog';
import { MainMenuProps } from './MainMenu';
import SearchTable from './components/frontend/SearchTable/SearchTable';
import { MainMenu } from './MainMenu';
import { MainSearch } from './MainSearch';
import { ChangeDetail } from './components/frontend/ChangeDetail';
import CTA from './components/frontend/CTA';
import { LegendPopover } from './components/frontend/Legend/LegendPopover';
import { useInterval } from './useInterval';

export interface AppProps {
  view: string;
}
export const App = (props: AppProps) => {
  const classes = appStyles(appMaterialTheme);

  const [locationData] = useState(useLocation());
  const [paramData] = useState(useParams());
  const [currentView, setCurrentView] = useState(props.view);
  const [editorSelected, setEditorSelected] = useState(false);
  const [aboutSelected, setAboutSelected] = useState(false);
  const [viewChangesSelected, setViewChangesSelected] = useState(false);
  const [viewBibleReaderSelected, setViewBibleReaderSelected] = useState(false);
  const [searchActivated, setSearchActivated] = useState(false);
  const [viewChangeDetailSelected, setViewChangeDetailSelected] = useState(false);
  //
  // auth
  //
  // check every 30 seconds
  const delay = 30000;
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthCheckRunning, setIsAuthCheckRunning] = useState(false);
  const [token, setToken] = useState('');

  const [mainMenuAnchorEl, setMainMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [bookNames, setBookNames] = useState([]);
  const [searchTextFinal, setSearchTextFinal] = useState('');
  const [searchID, setSearchID] = useState(0);
  const [viewChangeID, setViewChangeID] = useState(0);

  const [bibleReaderSettings, setBibleReaderSettings] = useState<BibleReaderProps>({
    searchID: searchID,
    bookName: 'Genesis',
    bookNumber: 1,
    chapterNumber: 1,
    verseNumber: 1,
  });

  useEffect(() => {
    if (paramData?.changesId) {
      let viewChange = parseInt(paramData.changesId, 10);
      if (isNaN(viewChange)) {
        console.log('invalid change id, setting to 1');
        viewChange = 1;
      }
      //console.log(`app: view change set to ${viewChange}`);
      setViewChangeID(viewChange);
    }
  }, [paramData?.changesId]);

  const handleMainMenuClose = () => {
    setMainMenuAnchorEl(null);
  };

  const handleLogin = useCallback(() => {
    // not authenticated, show the dialog
    setShowLoginDialog(true);
    handleMainMenuClose();
  }, []);

  const handleLogout = useCallback(() => {
    const cookies = new Cookies();
    cookies.remove(COOKIE_NAME);
    handleMainMenuClose();
  }, []);

  const handleAdminEditor = useCallback(() => {
    setViewBibleReaderSelected(false);
    setViewChangesSelected(false);
    setAboutSelected(false);
    setViewChangeDetailSelected(false);
    setSearchActivated(false);
    setEditorSelected(true);
    handleMainMenuClose();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewChangeDetail = () => {
    setViewBibleReaderSelected(false);
    setEditorSelected(false);
    setSearchActivated(false);
    setAboutSelected(false);
    setViewChangesSelected(false);
    setViewChangeDetailSelected(true);
    handleMainMenuClose();
  };

  useEffect(() => {
    switch (currentView) {
      case 'changes':
        setViewBibleReaderSelected(false);
        setEditorSelected(false);
        setSearchActivated(false);
        setViewChangeDetailSelected(false);
        setAboutSelected(false);
        setViewChangesSelected(true);
        window.history.pushState({}, '', '/changes');
        break;
      case 'reader':
        //console.log('Setting to reader view...');
        setEditorSelected(false);
        setViewChangesSelected(false);
        setViewChangeDetailSelected(false);
        setSearchActivated(false);
        setAboutSelected(false);
        setViewBibleReaderSelected(true);
        window.history.pushState({}, '', '/reader');
        break;
      default:
        // about
        setEditorSelected(false);
        setViewChangesSelected(false);
        setViewChangeDetailSelected(false);
        setSearchActivated(false);
        setViewBibleReaderSelected(false);
        setAboutSelected(true);
        window.history.pushState({}, '', '/');
        break;
    }
    handleMainMenuClose();
  }, [locationData, currentView]);

  const handleViewBibleReader = (event: any) => {
    setCurrentView('reader');
  };

  const handleViewAbout = (event: any) => {
    setCurrentView('about');
  };

  const handleViewChanges = (event: any) => {
    setCurrentView('changes');
  };

  /*
    Parameters
      1) BCV will switch to reader view and show the specific passage
      2) Change ID - show change detail by ID
      3) Change BCV - show change detail for specified BCV
  */
  const BCVSearchRegExp = /(?<BOOKNAME>.*)\s(?<CHAPTER>\d+):(?<VERSE>\d+)/;
  // TODO: implement this
  //const ChangeIDDetailRegExp = /changes (?<CHANGEID>\d+)/;
  // TODO: implement this
  //const ChangeBCVDetailRegExp = /changes (?<BOOKNAME>.*)\s(?<CHAPTER>\d+):(?<VERSE>\d+)/;

  const doSearch = (searchText: string) => {
    //console.log('app-doSearch: ' + searchText);
    setSearchTextFinal(searchText);
    // check if the text matches BCV, if it does, just show the reader with those settings
    if (searchText?.length > 0) {
      if (searchText !== null && searchText?.match(BCVSearchRegExp)) {
        // @ts-ignore
        const groups = searchText.match(BCVSearchRegExp).groups;
        // convert name to number
        // @ts-ignore
        const b = GetBookNumber(bookNames, groups.BOOKNAME);
        // set the bookname to proper case format
        bibleReaderSettings.bookName = bookNames[b - 1];
        bibleReaderSettings.bookNumber = b;
        // @ts-ignore
        bibleReaderSettings.chapterNumber = parseInt(groups.CHAPTER, 10);
        // @ts-ignore
        bibleReaderSettings.verseNumber = parseInt(groups.VERSE, 10);
        const nextID = searchID + 1;
        setSearchID(nextID);
        bibleReaderSettings.searchID = nextID;
        setBibleReaderSettings(bibleReaderSettings);
        //handleViewBibleReader(null);
        // clear
        setSearchTextFinal('');
        //handleViewBibleReader(null);
        setCurrentView('reader');
      } else {
        // otherwise perform the search against the database
        setViewBibleReaderSelected(false);
        setViewChangesSelected(false);
        setAboutSelected(false);
        setViewChangeDetailSelected(false);
        setEditorSelected(false);
        setSearchActivated(true);
      }
    }
  };

  const handleToken = (value: string) => {
    if (!value) {
      setIsAuthenticated(false);
      // user cancelled dialog
      setShowLoginDialog(false);
      return;
    }
    // create session cookie
    const cookies = new Cookies();
    // ssl not required for dev mode
    let secure = false;
    if (env.get('NODE_ENV').default('production').asString() === 'development') {
      secure = false;
    }
    cookies.set(COOKIE_NAME, value, { path: '/', maxAge: MAX_SESSION, secure: secure, sameSite: true });
    const aToken = GetTokenFromCookie();
    if (aToken) {
      //console.log(`handleToken(): value from cookie is :${aToken}:`);
      setToken(aToken);
      console.log('resuming auth check interval...');
      setIsAuthCheckRunning(true);
    } else {
      console.log('handleToken(): failed to get token from cookie!');
      return;
    }
    setIsAuthenticated(true);
    setShowLoginDialog(false);
    //console.log("handleToken(): done");
  };

  const forceLogout = useCallback(() => {
    //console.log(`forceLogout called`);
    if (isAuthenticated) {
      // can't trigger current view here, have to call these functions directly
      let resetView = false;
      if (editorSelected) {
        resetView = true;
        setEditorSelected(false);
        setViewChangesSelected(false);
        setViewChangeDetailSelected(false);
        setSearchActivated(false);
        setAboutSelected(false);
        setViewBibleReaderSelected(true);
      }
      setIsAuthenticated(false);
      console.log('pausing auth check interval...');
      setIsAuthCheckRunning(false);
      // forcing to /reader if the editors were being displayed
      if (resetView) {
        console.log('editor was open, switching to reader...');
        window.history.pushState({}, '', '/reader');
      }
    }
  }, [isAuthenticated, editorSelected]);

  const checkAuthentication = () => {
    const aToken = GetTokenFromCookie();
    if (aToken) {
      //console.log(`checkAuthentication: state token :${token}:`);
      //console.log(`checkAuthentication: cookie token :${aToken}:`);
      if (token !== aToken) {
        // this should never happen
        console.log(`WARNING: New Token updating token :${token}: to ${aToken}`);
        setToken(aToken);
      } else {
        console.log('token matches not updating');
      }
    } else {
      console.log('session cookie expired or not found...');
      return forceLogout();
    }
  };

  useInterval(
    () => {
      checkAuthentication();
    },
    isAuthCheckRunning ? delay : null
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetBookNames();
      if (data?.names) {
        setBookNames(data.names);
        // setBibleReaderSettings({...bibleReaderSettings, bookNames: data.names});
      } else {
        // TODO: maintenance mode
        console.log('server not responding...');
        return;
      }
    };
    if (bookNames.length === 0) {
      fetchData();
    }
  }, [bookNames]);

  const menuProps: MainMenuProps = {
    handleViewBibleReader: handleViewBibleReader,
    handleViewAbout: handleViewAbout,
    handleViewChanges: handleViewChanges,
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    handleAdminEditor: handleAdminEditor,
    handleMainMenuClose: handleMainMenuClose,
    isAuthenticated: isAuthenticated,
    setMainMenuAnchorEl: setMainMenuAnchorEl,
    mainMenuAnchorEl: mainMenuAnchorEl,
  };

  return (
    <ThemeProvider theme={appMaterialTheme}>
      <AppBar position='static'>
        <Toolbar>
          <MainMenu {...menuProps} />
          <Typography className={classes.title} variant='h6' noWrap>
            Scribe
          </Typography>
          <Tooltip title='Bible Reader View'>
            <IconButton
              style={{ marginLeft: '5px', marginRight: '5px' }}
              color='inherit'
              onClick={handleViewBibleReader}>
              <HomeIcon />
            </IconButton>
          </Tooltip>
          <LegendPopover />
          <MainSearch bookNames={bookNames} doSearch={doSearch} />
        </Toolbar>
      </AppBar>
      {showLoginDialog && <LoginDialog handleToken={handleToken} />}
      {viewChangesSelected && <ChangeTable viewChange={viewChangeID} />}
      {viewChangeDetailSelected && <ChangeDetail viewChange={viewChangeID} />}
      {editorSelected && <AdminMenu />}
      {viewBibleReaderSelected && <BibleReader {...bibleReaderSettings} />}
      {searchActivated && <SearchTable searchText={searchTextFinal} />}
      {aboutSelected && <About />}
      <CTA />
    </ThemeProvider>
  );
};

export default App;
