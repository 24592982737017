import React from 'react';

import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import FlareIcon from '@mui/icons-material/Flare';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import { appStyles, StyledMenuItem } from './Styles';

export interface MainMenuProps {
  handleViewBibleReader: any;
  handleViewAbout: any;
  handleViewChanges: any;
  handleLogin: any;
  handleLogout: any;
  handleAdminEditor: any;
  handleMainMenuClose: any;
  isAuthenticated: boolean;
  setMainMenuAnchorEl: any;
  mainMenuAnchorEl: any;
}
export const MainMenu = (props: MainMenuProps) => {
  const classes = appStyles();

  const handleMainMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.setMainMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        edge='start'
        className={classes.menuButton}
        color='inherit'
        aria-haspopup='true'
        aria-label='open drawer'
        onClick={handleMainMenuClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id='main-menu'
        PaperProps={{
          sx: { border: '1px solid #d3d4d5' },
        }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={props.mainMenuAnchorEl}
        keepMounted
        open={Boolean(props.mainMenuAnchorEl)}
        onClose={props.handleMainMenuClose}>
        <StyledMenuItem onClick={props.handleViewBibleReader}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Reader' />
        </StyledMenuItem>
        <StyledMenuItem onClick={props.handleViewChanges}>
          <ListItemIcon>
            <ImportContactsIcon />
          </ListItemIcon>
          <ListItemText primary='Changes' />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={props.handleViewAbout}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary='About' />
        </StyledMenuItem>
        <Divider />
        {props.isAuthenticated ? (
          <StyledMenuItem onClick={props.handleLogout}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </StyledMenuItem>
        ) : (
          <StyledMenuItem onClick={props.handleLogin}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={'Login'} />
          </StyledMenuItem>
        )}
        {props.isAuthenticated && (
          <StyledMenuItem onClick={props.handleAdminEditor}>
            <ListItemIcon>
              <FlareIcon />
            </ListItemIcon>
            <ListItemText primary='Editors' />
          </StyledMenuItem>
        )}
      </Menu>
    </>
  );
};
