import React, { useState } from 'react';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import MaterialTable from 'material-table';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { PreviewRow } from './Types';
import { Summary } from '../Summary';
import { ResidueViewerDetailed } from '../ResidueViewerDetailed';
import Emoji from 'react-emoji-render';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'orangered !important',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'orange',
          sx: { root: { backgroundColor: 'black' } },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'yellow',
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
        sx: {
          '& MuiToolbar-dense.MTableToolbar-root': {
            borderColor: 'yellow',
            paddingRight: '80px',
          },
          '& .MTableToolbar-title*': {
            borderColor: 'yellow',
            backgroundColor: 'purple !important',
            paddingRight: '80px',
          },
        },
      },
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            borderColor: 'yellow',
            paddingRight: '80px',
          },
          backgroundColor: '#7777AA',
          '.MTableToolbar-root': {
            borderColor: 'yellow',
            paddingRight: '80px',
          },
          '& .MTableToolbar-title*': {
            borderColor: 'yellow',
            backgroundColor: 'black',
            paddingRight: '80px',
          },
        },
        dense: {
          backgroundColor: '#607d8b', // #3299eb
          '& .MTableToolbar-root': {
            borderColor: 'yellow',
            backgroundColor: 'red',
            paddingRight: '80px',
          },
          sx: {
            backgroundColor: 'red !important',
            '& .MTableToolbar-root': {
              borderColor: 'yellow',
              paddingRight: '80px',
            },
            '& .MTableToolbar-title*': {
              borderColor: 'yellow',
              paddingRight: '80px',
            },
          },
        },
        regular: {
          backgroundColor: '#607d8b', // #3299eb
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
        sizeSmall: {
          paddingRight: '10px',
          paddingLeft: '3px',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
  },
});

export interface ChapterViewerProps {
  chapterText: [];
  bookName: string;
  bookNumber: number;
  chapterNumber: number;
}

/*
const chapterViewerStyleX = {
  backgroundColor: '#607d8b',
  searchFieldStyle: {
    minWidth: 4000,
  },
  MuiTableCell: {
    root: {
      padding: '10px',
    },
    sizeSmall: {
      paddingRight: '10px',
      paddingLeft: '3px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  MuiIconButton: {
    root: {
      backgroundColor: 'inherit !important',
      padding: 0,
    },
  },
} as const;
*/

/*
const meh = {
  searchFieldStyle: {
    minWidth: 500,
  },
  MaterialTableHeaderStyle: {
    backgroundColor: '#607d8b', // #3299eb
    color: '#FFF',
  },
  MaterialTableColumnHeaderStyle: {
    whiteSpace: 'nowrap'
  },
  MaterialTableCellStyle: { // this is doing something too
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'normal',
    padding: '1px',
    sizeSmall: {
      paddingRight: '10px',
      paddingLeft: '3px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  MuiIconButton: {
    root: {
      backgroundColor: 'inherit !important',
      padding: 0,
    },
  },
} as const;
*/
/*
  Shows a chapter
 */
export const ChapterViewer = (props: ChapterViewerProps) => {
  const [selectedRow, setSelectedRow] = useState({ Text: 'Genesis', Verse: 1, expandable: true } as PreviewRow);
  const emojiEnabled = false;
  const detailPanel = [
    (rowData: any) => ({
      iconOpen: () => {
        if (rowData.expandable && rowData.memoryOfVerse !== '') {
          // residue and memory
          return <ErrorOutlineOutlinedIcon fontSize='large' color='error' />;
        }
        if (rowData.hasMemories) {
          return <ErrorOutlineOutlinedIcon fontSize='large' color='action' />;
        }
        if (rowData.notes !== '') {
          return <WarningAmberOutlinedIcon fontSize='large' color='info' />;
        }
        return <CheckCircleOutlineIcon fontSize='large' color='disabled' />;
      },
      icon: () => {
        if (rowData.expandable && rowData.memoryOfVerse !== '') {
          // residue and memory
          return <ErrorOutlineOutlinedIcon fontSize='large' color='error' />;
        }
        if (rowData.hasMemories) {
          return <ErrorOutlineOutlinedIcon fontSize='large' color='action' />;
        }
        if (rowData.notes !== '') {
          return <WarningAmberOutlinedIcon fontSize='large' color='info' />;
        }
        return <CheckCircleOutlineIcon fontSize='large' color='disabled' />;
      },
      tooltip: !rowData.expandable ? 'No Change' : 'Show Memory and Notes',
      render: () => {
        if (rowData.expandable) {
          const BCV = `${props.bookName} ${props.chapterNumber}:${rowData.Verse}`;
          return (
            <Summary
              BCV={BCV}
              memoryOfVerse={rowData.memoryOfVerse}
              notes={rowData.notes}
              verseText={rowData.Text}
              changeID={rowData.changeID}
              detectedAt={''}
            />
          );
        }
      },
    }),
    (rowData: any) => ({
      openIcon: () => {
        if (rowData.expandable && rowData.hasMemories) {
          return <PhotoLibraryOutlinedIcon fontSize='large' color='secondary' />;
        }
        if (rowData.expandable) {
          return <NoteOutlinedIcon fontSize='large' color='disabled' />;
        }
        return <NoteOutlinedIcon fontSize='large' color='disabled' />;
      },
      icon: () => {
        if (rowData.expandable && rowData.hasMemories) {
          return <PhotoLibraryOutlinedIcon fontSize='large' color='secondary' />;
        }
        if (rowData.expandable) {
          return <NoteOutlinedIcon fontSize='large' color='disabled' />;
        }
        return <NoteOutlinedIcon fontSize='large' color='disabled' />;
      },
      tooltip: !rowData.expandable ? 'No Residue' : 'Show Residue',
      render: () => {
        if (rowData.expandable && rowData.hasMemories) {
          return <ResidueViewerDetailed data={rowData} chapterViewerProps={props} />;
        }
      },
    }),
  ];

  let formatted: PreviewRow[] = [];
  if (typeof props.chapterText !== 'undefined') {
    if (props.chapterText.length > 0) {
      const verses = props.chapterText as any;
      for (let i = 0; i < verses.length; i++) {
        if (verses[i]) {
          formatted.push({
            Verse: i + 1,
            Text: verses[i].text,
            expandable: verses[i].hasChange,
            memoryOfVerse: verses[i].restoredText,
            notes: verses[i].notes,
            hasMemories: verses[i].hasMemories,
            changeID: verses[i].changeID,
          });
        }
      }
    }
  }
  const title = `${props.bookName} ${props.chapterNumber}`;
  /*
        <Box
        sx={{
          '& td.MuiTableCell-root': { // this actually works for submembers
            fontSize: '14px',
            textAlign: 'left',
            whiteSpace: 'normal',
          },
          '& .MuiButtonBase-root.MuiIconButton-root': { // X button
              backgroundColor: 'grey',
              padding: '0px',
          },
        }}
      >
*/
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <MaterialTable
          data={formatted}
          onRowClick={(evt, selectedRow, togglePanel) => {
            setSelectedRow(selectedRow as PreviewRow);
            togglePanel && togglePanel();
          }}
          style={{
            backgroundColor: '#607d8b',
            padding: '0px',
          }}
          //style={chapterViewerStyleX}
          options={{
            header: false,
            padding: 'dense',
            paging: false,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              minWidth: 500,
            },
            sorting: true,
            rowStyle: (rowData) => ({
              fontSize: '14px',
              backgroundColor: selectedRow && selectedRow.Verse === rowData.Verse ? '#EEE' : '#FFF',
            }),
          }}
          columns={[
            {
              title: 'Verse',
              field: 'Verse',
              width: 'auto',
            },
            {
              title: '',
              field: 'Text',
              width: 'auto',
              render: (rowData) => (emojiEnabled ? <Emoji text={rowData?.Text} /> : <>{rowData?.Text}</>),
              headerStyle: {
                whiteSpace: 'nowrap',
              },
              cellStyle: {
                fontSize: '14px',
                textAlign: 'left',
                whiteSpace: 'normal',
                padding: '1px',
              },
            },
          ]}
          title={title}
          detailPanel={detailPanel}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
