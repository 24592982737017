import React, { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { appStyles } from './Styles';

export interface MainSearchProps {
  doSearch: any;
  bookNames: any;
}
export const MainSearch = (props: MainSearchProps) => {
  const classes = appStyles();
  const [searchTextInput, setSearchTextInput] = useState('');
  const [searchTextInputValue, setSearchTextInputValue] = useState('');

  const handleSearchInputChange = (event: any) => {
    setSearchTextInput(event.target.textContent);
  };

  return (
    <Autocomplete
      id='free-solo-demo'
      size='small'
      freeSolo
      fullWidth={true}
      handleHomeEndKeys
      selectOnFocus
      onChange={handleSearchInputChange}
      inputValue={searchTextInputValue}
      onInputChange={(event, newInputValue) => {
        setSearchTextInputValue(newInputValue);
      }}
      value={searchTextInput}
      options={props.bookNames.map((option: string) => option)}
      renderInput={(params) => (
        <TextField
          label='Search the Bible...'
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              props.doSearch(searchTextInputValue);
            }
          }}
          margin='dense'
          classes={{
            root: classes.searchAutoComplete,
          }}
          {...params}
          variant='outlined'
        />
      )}
    />
  );
};
