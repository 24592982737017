import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

interface DropFilesProps {
  acceptedDropFiles: File[];
  setAcceptedDropFiles: any;
}
export function DropFiles(props: DropFilesProps) {
  //const [acceptedDropFiles, setAcceptedDropFiles] = React.useState<File[]>([]);
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>(props.acceptedDropFiles);

  const onDrop = useCallback(
    (files: any) => {
      //console.log("ondrop");
      // Do something with the files
      setAcceptedFiles(files);
      props.setAcceptedDropFiles(files);
    },
    [props]
  );

  const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  const acceptedFilesItems = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const rejectedFilesItems = rejectedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <Container>
      <div {...getRootProps({ className: 'dropzone' })}>
        <Paper elevation={3} style={{ display: 'flex', alignItems: 'center' }}>
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon style={{ margin: '10' }} />
          <Typography>
            Drag 'n' drop images here, or click to select files (Only *.jpeg and *.png images will be accepted)
          </Typography>
        </Paper>
      </div>
      <aside>
        <h4>Accepted files</h4>
        <ul>{acceptedFilesItems}</ul>
        <ul>{rejectedFilesItems}</ul>
      </aside>
    </Container>
  );
}
