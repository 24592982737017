import React from 'react';
// MaterialUI
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
// Scribe
import { DropFiles } from './DropFiles';
import LinearWithValueLabel from './FileUploader';
import { residueDialogStyles } from './Styles';
import { BackendDatePicker } from './BackendDatePicker';
import { AddResidue, AddResidueLookup, ResidueFileUpload } from '../../common/Api';

import { AddResidueDialogProps } from './types';

function getSteps() {
  return ['Date of Residue', 'Comments', 'Residue', 'Finished'];
}

export function AddResidueDialog(props: any) {
  const classes = residueDialogStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [acceptedDropFiles, setAcceptedDropFiles] = React.useState<File[]>([]);
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [resetDisabled, setResetDisabled] = React.useState(false);
  const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  const [cancelCloseButtonText, setCancelCloseButtonText] = React.useState('Cancel');
  const [displayedProgress, setDisplayedProgress] = React.useState(0);
  const [displayedProgressMessages, setDisplayedProgressMessages] = React.useState<string[]>([]);

  const initProps: AddResidueDialogProps = {
    selectedDate: new Date(),
    notes: '',
    onAddResidueDialogClosed: props.onAddResidueDialogClosed,
    memoryID: props.memoryID,
    alertMessage: '',
    alertSeverity: 'info',
    showAlert: false,
    bookNumber: props.bookNumber,
    chapterNumber: props.chapterNumber,
    verseNumber: props.verseNumber,
  };
  const [editorSettings, setEditorSettings] = React.useState<AddResidueDialogProps>(initProps);

  const onSave = async () => {
    let saveSucceeded = true;
    setSaveDisabled(true);
    setResetDisabled(true);
    // post the memory, and return the ID with confirmation dialog
    // create residue first
    let residueImageIDs: string[] = [];
    setDisplayedProgress(0);
    setDisplayedProgressMessages((displayedProgressMessages) => [...displayedProgressMessages, 'Saving...']);

    setShowUploadProgress(true);
    for (let i = 0; i < acceptedDropFiles.length; i++) {
      setDisplayedProgress(displayedProgress + 1);
      setDisplayedProgressMessages((displayedProgressMessages) => [
        ...displayedProgressMessages,
        `Uploading file ${acceptedDropFiles[i].name}`,
      ]);
      // upload file
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(acceptedDropFiles[i]);
      let formData = new FormData();
      formData.append('bookNumber', editorSettings.bookNumber.toString());
      formData.append('chapterNumber', editorSettings.chapterNumber.toString());
      formData.append('verseNumber', editorSettings.verseNumber.toString());
      formData.append('file', acceptedDropFiles[i]);
      // upload it
      editorSettings.alertMessage = 'Upload: In Progress';
      editorSettings.alertSeverity = 'success';
      editorSettings.showAlert = true;
      setEditorSettings({ ...editorSettings });
      const response = await ResidueFileUpload(formData);
      //console.log('uploaded file...' + response);
      // status, message, bytes, filename
      if (response.status === 'OK') {
        editorSettings.alertMessage = `Upload: ${response.message} bytes sent: ${response.bytes}`;
        editorSettings.alertSeverity = 'success';
      } else {
        editorSettings.alertMessage = `Upload: ${response.message}`;
        editorSettings.alertSeverity = 'error';
        saveSucceeded = false;
      }
      setEditorSettings({ ...editorSettings });
      const newFileName =
        'residue/' +
        editorSettings.bookNumber.toString().padStart(3, '0') +
        '/' +
        editorSettings.chapterNumber.toString().padStart(3, '0') +
        '/' +
        editorSettings.verseNumber.toString().padStart(3, '0') +
        '/' +
        acceptedDropFiles[i].name;
      const residuePayload = {
        imageDate: editorSettings.selectedDate,
        imageURL: newFileName,
        imagePath: newFileName,
        notes: editorSettings.notes,
      };
      setDisplayedProgressMessages((displayedProgressMessages) => [
        ...displayedProgressMessages,
        'Creating Residue in Database...',
      ]);
      // now create
      const residueResponse = await AddResidue(residuePayload);
      // status, message, id
      if (residueResponse.status === 'OK') {
        editorSettings.alertMessage = `Creating Residue: ${residueResponse.message} bytes sent: ${residueResponse.bytes}`;
        editorSettings.alertSeverity = 'success';
      } else {
        editorSettings.alertMessage = `Creating Residue: ${residueResponse.message}`;
        editorSettings.alertSeverity = 'error';
        saveSucceeded = false;
      }
      setEditorSettings({ ...editorSettings });
      //console.log("Created residue with id " + residueResponse.id);
      // save the id
      residueImageIDs.push(residueResponse.id);
    }
    // next create residue lookups
    for (let i = 0; i < residueImageIDs.length; i++) {
      setDisplayedProgress(displayedProgress + 1);
      setDisplayedProgressMessages((displayedProgressMessages) => [
        ...displayedProgressMessages,
        `Updating Database for ResidueLookup: ${residueImageIDs[i]}`,
      ]);
      const residueLookupPayload = {
        ID: 0,
        residueImageID: parseInt(residueImageIDs[i], 10),
        memoryID: parseInt(editorSettings.memoryID, 10),
      };
      // now create residue lookups
      const residueLookupData = await AddResidueLookup(residueLookupPayload);
      //console.log(residueLookupData);
      editorSettings.alertMessage = 'Create Residue Lookup Status: ' + residueLookupData.message;
      if (residueLookupData.status === 'OK') {
        editorSettings.alertSeverity = 'success';
      } else {
        editorSettings.alertSeverity = 'error';
        saveSucceeded = false;
      }
      setEditorSettings({ ...editorSettings });
    }
    //console.log("done creating residue lookup entries");
    setDisplayedProgress(100);
    // set alert with status of save
    if (saveSucceeded) {
      setDisplayedProgressMessages((displayedProgressMessages) => [...displayedProgressMessages, `Done!`]);
      editorSettings.alertSeverity = 'success';
      editorSettings.alertMessage = 'Save Status: OK';
    } else {
      setDisplayedProgressMessages((displayedProgressMessages) => [...displayedProgressMessages, `Failed!`]);
      editorSettings.alertSeverity = 'error';
      editorSettings.alertMessage = 'Save Status: ERROR';
    }
    editorSettings.showAlert = true;
    setAcceptedDropFiles([]);
    setEditorSettings({ ...editorSettings });
  };

  const steps = getSteps();
  const totalSteps = () => {
    return getSteps().length;
  };

  const handleSave = async () => {
    await onSave();
  };
  const handleClose = () => {
    setAcceptedDropFiles([]);
    props.onAddResidueDialogClosed();
  };

  const handleCloseAlertNotification = () => {
    setSaveDisabled(false);
    setResetDisabled(false);
    setCancelCloseButtonText('Close');
  };

  const handleReset = () => {
    editorSettings.notes = '';
    setShowUploadProgress(false);
    setDisplayedProgressMessages([]);
    setAcceptedDropFiles([]);
    setSaveDisabled(true);
    setResetDisabled(false);
    setEditorSettings({ ...editorSettings });
    setActiveStep(0);
    setCompleted(new Set<number>());
    setSkipped(new Set<number>());
  };

  const isStepOptional = (step: number) => {
    switch (step) {
      case 1:
        return true;
      default:
        return false;
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    if (completedSteps() === totalSteps() - skippedSteps()) {
      if (saveDisabled) {
        setSaveDisabled(false);
      }
    }
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);
    if (completed.size !== totalSteps() - skippedSteps()) {
      if (isLastStep()) {
        // on the last step, enable the save button and do not advance to a skipped step
        setSaveDisabled(false);
      } else {
        // advance to next step
        handleNext();
      }
    } else {
      setSaveDisabled(false);
      setResetDisabled(false);
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  return (
    <div>
      <Dialog classes={{ paper: classes.dialogPaper }} open onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Add Residue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will guide you through the process of adding residue to the selected memory
          </DialogContentText>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const buttonProps: { optional?: React.ReactNode } = {};
                if (isStepOptional(index)) {
                  buttonProps.optional = <Typography variant='caption'>Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} completed={isStepComplete(index)} {...stepProps}>
                    <StepButton onClick={handleStep(index)} {...buttonProps}>
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {activeStep === 0 && (
            <Container>
              <Paper elevation={3}>
                <BackendDatePicker editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
              </Paper>
            </Container>
          )}
          {activeStep === 1 && (
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                editorSettings.notes = event.target.value;
                setEditorSettings({ ...editorSettings });
              }}
              multiline
              rows='4'
              value={editorSettings.notes}
              autoFocus
              margin='dense'
              id='notes'
              label='Notes about residue'
              fullWidth
            />
          )}
          {activeStep === 2 && (
            <DropFiles acceptedDropFiles={acceptedDropFiles} setAcceptedDropFiles={setAcceptedDropFiles} />
          )}
          <div>
            {allStepsCompleted() ? (
              <Alert severity='info'>
                <AlertTitle>Ready to Save</AlertTitle>
                All required steps completed, ready to save!
              </Alert>
            ) : (
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  color='primary'
                  variant='contained'
                  className={classes.button}>
                  Back
                </Button>
                <Button variant='contained' color='primary' onClick={handleNext} className={classes.button}>
                  Next
                </Button>
                {isStepOptional(activeStep) && !completed.has(activeStep) && (
                  <Button variant='contained' color='primary' onClick={handleSkip} className={classes.button}>
                    Skip
                  </Button>
                )}
                {activeStep !== steps.length &&
                  (completed.has(activeStep) ? (
                    <Typography variant='caption' className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button variant='contained' color='primary' onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                    </Button>
                  ))}
              </div>
            )}
          </div>
          {showUploadProgress && (
            <LinearWithValueLabel progress={displayedProgress} messages={displayedProgressMessages} />
          )}
          <Snackbar open={editorSettings.showAlert} autoHideDuration={5000} onClose={handleCloseAlertNotification}>
            <Alert
              variant='filled'
              severity={editorSettings.alertSeverity}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    handleClose();
                  }}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }>
              {editorSettings.alertMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} disabled={resetDisabled} color='primary'>
            Reset
          </Button>
          <Button onClick={handleSave} disabled={saveDisabled} color='primary'>
            Save
          </Button>
          <Button onClick={handleClose} color='primary'>
            {cancelCloseButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
