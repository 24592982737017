import React from 'react';
import copy from 'copy-to-clipboard';

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import { Magnifier } from 'react-image-magnifiers';
import ShareIcon from '@mui/icons-material/Share';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    dialogToolbar: {
      padding: '16px',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    residueID: {
      textAlign: 'right',
    },
    residueDate: {
      textAlign: 'center',
      flex: 6,
    },
    detailNotes: {
      width: '100%',
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    root: {
      width: '100%',
      backgroundColor: '#607d8b',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export interface ResidueViewerDialogProps {
  selectedTile: any;
  handleClose: any;
  changeID: any;
}
export const ResidueViewerDialog = (props: ResidueViewerDialogProps) => {
  const classes = useStyles();
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const getClipboardData = (): string => {
    return `${window.location.origin}/changes/${props.changeID}`;
  };

  const copyToClipBoardAction = () => {
    copy(getClipboardData());
    setAlertMessage('Link Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const getClipboardDataForDiscord = (): string => {
    return `${window.location.origin}/changeDetail/${props.changeID}`;
  };

  const copyToClipBoardForDiscordAction = () => {
    copy(getClipboardDataForDiscord());
    setAlertMessage('Link for Discord Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  return (
    <Dialog
      aria-label='residueviewerdetailed-dialog'
      scroll='paper'
      fullWidth={true}
      maxWidth='xl'
      open={props.selectedTile && props.selectedTile.imagePath !== ''}
      onClose={props.handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.dialogToolbar}>
          <IconButton edge='start' color='inherit' onClick={props.handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Tooltip title='Open Image in New Window'>
            <IconButton
              aria-label='residueviewer-open-image-in-new-window'
              onClick={() => {
                window.open(props.selectedTile.imagePath);
              }}>
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Copy Link'>
            <IconButton
              aria-label='residueviewer-copy-link'
              onClick={() => {
                copyToClipBoardAction();
              }}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Detail Link'>
            <IconButton
              aria-label='residueviewer-discord-copy-link'
              onClick={() => {
                copyToClipBoardForDiscordAction();
              }}>
              <IosShareOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Typography variant='h6' className={classes.residueDate}>
            Date: {props.selectedTile && props.selectedTile.imageDate}
          </Typography>
          <Typography variant='h6' className={classes.residueID}>
            Residue ID: {props.selectedTile && props.selectedTile.ID}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Paper className={classes.detailNotes} variant='outlined' style={{ alignSelf: 'center' }}>
          <Typography variant='h6'>{props.selectedTile && props.selectedTile.notes}</Typography>
        </Paper>
        <Magnifier imageSrc={props.selectedTile.imagePath} imageAlt={props.selectedTile.imagePath} />
      </DialogContent>
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant='filled' severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
