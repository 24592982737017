import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ThemeProvider } from '@mui/styles';
import { FrontendMaterialTheme } from '../Theme';
import { bibleReaderStyles } from './Styles';
import { Typography } from '@mui/material';
import Emoji from 'react-emoji-render';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

export interface ContextViewerProps {
  contextText: string[];
  bookName: string;
  chapterNumber: number;
  verseNumber: number;
}

/*
  Shows verses surrounding a specified verse
 */
export const ContextViewer = (props: ContextViewerProps) => {
  const classes = bibleReaderStyles();
  const formatted = [];
  const emojiEnabled = false;

  if (typeof props.contextText !== 'undefined') {
    if (props.contextText.length > 0) {
      const verses = props.contextText as any;
      // up to 3 before
      let startAt = props.verseNumber - 3;
      if (startAt < 0) {
        startAt = 0;
      }
      let endAt = verses.length;
      // up to 3 after
      endAt = props.verseNumber + 3;
      if (endAt > verses.length) {
        endAt = verses.length;
      }
      for (let i = startAt; i < endAt; i++) {
        if (verses[i]) {
          if (i + 1 === props.verseNumber) {
            emojiEnabled
              ? formatted.push(
                  <strong key={i}>
                    {i + 1}. <Emoji text={verses[i].text} />{' '}
                  </strong>
                )
              : formatted.push(
                  <strong key={i}>
                    {i + 1}. {verses[i].text}{' '}
                  </strong>
                );
          } else {
            emojiEnabled
              ? formatted.push(
                  <span key={i}>
                    {i + 1}. {verses[i].text}{' '}
                  </span>
                )
              : formatted.push(
                  <span key={i}>
                    {i + 1}. <Emoji text={verses[i].text} />{' '}
                  </span>
                );
          }
        }
      }
    }
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontendMaterialTheme}>
        <Card className={classes.contextViewer} variant='outlined'>
          <CardContent>
            <Typography className={classes.contextViewerTitle} color='textSecondary' gutterBottom>
              {props.bookName} {props.chapterNumber}:{props.verseNumber} Context
            </Typography>
            <Typography className={classes.contextViewerText}>{formatted}</Typography>
          </CardContent>
        </Card>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
