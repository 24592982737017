import * as env from 'env-var';
import { encode } from 'base-64';
import { CallAPIWithAuth } from './Api';

let urlPrefix = '';
let isDevMode = false;
if (env.get('NODE_ENV').default('production').asString() === 'development') {
  isDevMode = true;
}
if (isDevMode) {
  urlPrefix = `http://localhost:7777`;
}

export const Login = async (username: string, password: string) => {
  const encodedValue = encodeURIComponent(username);
  return await fetch(`${urlPrefix}/v1/auth/token?userName=${encodedValue}`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Basic ' + encode(username + ':' + password),
    }),
    redirect: 'follow',
  })
    .then(async (response) => {
      const result = await response.json();
      if (result.status === 'unauthorized') {
        return { status: false, token: null, message: 'Authorization Failed' };
      }
      return { status: true, token: result.token, message: 'Authorized!' };
    })
    .catch((error) => {
      console.error(error);
      return { status: false, token: null, message: error };
    });
};

export const GetUsers = async () => {
  const url = `${urlPrefix}/v1/GetUsers`;
  return await CallAPIWithAuth(url, '');
};

export const AddUser = async (payload: any) => {
  const url = `${urlPrefix}/v1/AddUser`;
  return await CallAPIWithAuth(url, payload);
};

export const DeleteUserByID = async (payload: any) => {
  const url = `${urlPrefix}/v1/DeleteUserByID`;
  return await CallAPIWithAuth(url, payload);
};

export const UpdateUser = async (payload: any) => {
  const url = `${urlPrefix}/v1/UpdateUser`;
  return await CallAPIWithAuth(url, payload);
};

export const GetRoles = async (payload: any) => {
  const url = `${urlPrefix}/v1/GetRoles`;
  return await CallAPIWithAuth(url, payload);
};
