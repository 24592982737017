import React from 'react';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface ConfirmationDialogProps {
  title: string;
  titleColor?: string; // TODO
  text: string;
  handleYes: any;
  handleNo: any;
  open: boolean;
}

const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: 'red',
    color: 'white',
  },
}));

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const handleNo = () => {
    setOpen(false);
    props.handleNo();
  };
  const handleYes = () => {
    setOpen(false);
    props.handleYes();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle className={classes.title} id='alert-dialog-title'>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color='primary'>
            YES
          </Button>
          <Button onClick={handleNo} color='primary' autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
