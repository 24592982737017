import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ListSubheader from '@mui/material/ListSubheader';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
});

export const Legend = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <Paper variant='outlined' sx={{ width: '300px' }}>
        <List>
          <ListSubheader>Icons and their meanings</ListSubheader>
          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <ErrorOutlineOutlinedIcon fontSize='large' color='error' />
            </ListItemIcon>
            <ListItemText>Memory of verse is available</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <WarningAmberOutlinedIcon fontSize='large' color='info' />
            </ListItemIcon>
            <ListItemText>Notes available but no memory of verse available</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <CheckCircleOutlineIcon fontSize='large' color='disabled' />
            </ListItemIcon>
            <ListItemText>No entries for verse</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <PhotoLibraryOutlinedIcon fontSize='large' color='secondary' />
            </ListItemIcon>
            <ListItemText>Residue is available</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <NoteOutlinedIcon fontSize='large' color='disabled' />
            </ListItemIcon>
            <ListItemText>No Residue is available</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <ImportContactsIcon fontSize='large' color='success' />
            </ListItemIcon>
            <ListItemText>Memory of verse is available</ListItemText>
          </ListItem>

          <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <SpeakerNotesIcon fontSize='large' color='info' />
            </ListItemIcon>
            <ListItemText>Notes for verse are available</ListItemText>
          </ListItem>
        </List>
      </Paper>
    </ThemeProvider>
  );
};
