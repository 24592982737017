export const changeTypes = ['Added', 'Duplicate', 'Grammar', 'Removed', 'Other', 'Punctuation', 'Word'];

export interface ChangeProps {
  bookName: string | null;
  bookNumber: number;
  bookNames: [];
  bookMaxChapters: number;
  chapter: number;
  verse: number;
  inputBookValue: string;
  selectedChangeID: string | null;
  selectedChange: ChangeResponse | null;
  inputExistingChangeValue: string;
  changeType: string | null;
  inputChangeTypeValue: string;
  source: string;
  keywords: string;
  notes: string;
  wordCount: number;
  isMissing: boolean;
  isHeresy: boolean;
  isMeaningChange: boolean;
  isDoctrineChange: boolean;
  hasFlipFlops: boolean;
  isPunctuationError: boolean;
  punctuationErrors: number;
  isDuplicate: boolean;
  isNew: boolean;
  isCapitalizationError: boolean;
  isHonorificError: boolean;
  isGrammarError: boolean;
  grammarErrors: number;
  changeNumber: string | 'TBD';
  selectedDate: Date;
  selectedVerseText: string;
  onChangeBook: any;
  onChangeChapter: any;
  onChangeVerse: any;
}

export interface ChangeResponse {
  BCV: string;
  CreatedAt: Date;
  DeletedAt: Date;
  ID: number;
  KJV: any;
  MemoriesOfVerse: any;
  UpdatedAt: string;
  book: number;
  bookName: string;
  capitalizationGeneral: boolean;
  capitalizationHonorifics: boolean;
  changeType: string;
  chapter: number;
  currentVerse: string;
  detectedAt: Date;
  downVotes: number;
  grammarErrorCount: number;
  hasFlipflops: boolean;
  hasGrammarErrors: boolean;
  hasPunctuationErrors: boolean;
  isDoctrineChange: boolean;
  isDuplicate: boolean;
  isHeresy: boolean;
  isMeaningChange: boolean;
  isMissing: boolean;
  isNew: boolean;
  keywords: string;
  kjvId: number;
  memorySummary: any;
  notes: string;
  punctuationErrorCount: number;
  source: string;
  upVotes: number;
  verse: number;
  wordChangeCount: number;
}

export interface MemoryResponse {
  CreatedAt: Date;
  DeletedAt: Date;
  UpdatedAt: string;
  ID: number;
  text: string;
  textURL: string;
  textURLEnabled: boolean;
  imageURL: string;
  imageURLEnabled: boolean;
  videoURL: string;
  videoEnabled: boolean;
  keywords: string;
  notes: string;
  book: number;
  chapter: number;
  verse: number;
  fixesGrammarErrors: boolean;
  fixesPunctuationErrors: boolean;
  fixesWordChanges: boolean;
  downVotes: number;
  upVotes: number;
}

export interface MemoryProps {
  bookName: string | null;
  bookNumber: number;
  bookNames: [];
  bookMaxChapters: number;
  chapter: number;
  verse: number;
  inputBookValue: string;
  inputExistingChangeValue: string;
  inputExistingMemoryValue: string;
  keywords: string;
  notes: string;
  imageURL: string;
  imageURLEnabled: boolean;
  text: string;
  textURL: string;
  textURLEnabled: boolean;
  videoURL: string;
  videoEnabled: boolean;
  fixesGrammarErrors: boolean;
  fixesPunctuationErrors: boolean;
  fixesWordChanges: boolean;
  upVotes: number;
  downVotes: number;
  selectedChangeID: string;
  selectedChange: ChangeResponse | null;
  selectedDate: Date;
  selectedMemory: MemoryResponse | null;
  selectedMemoryID: string | null;
  selectedVerseText: string;
  memoryNumber: string | 'TBD';
  onChangeBook: any;
  onChangeChapter: any;
  onChangeVerse: any;
}

export interface AdminProps {
  isLoading: boolean;
  showAddChangeDialog: boolean;
  addChangeDialogClosed: any;
  showEditChangeDialog: boolean;
  editChangeDialogClosed: any;
  showAddMemoryDialog: boolean;
  addMemoryDialogClosed: any;
  showEditMemoryDialog: boolean;
  editMemoryDialogClosed: any;
  editMemoryDisabled: boolean;
  selectedMemoryID: number;
  book: string;
  bookNumber: number;
  bookNames: [];
  chapter: number;
  verse: number;
  bookMaxChapters: number;
  chapterMaxVerses: number;
  changeExists: boolean;
  changeData: any;
  memoryData: any;
  inputBookValue: string;
  selectedVerseText: string;
  selectedDate: Date;
  onChangeBook: any;
  onChangeChapter: any;
  onChangeVerse: any;
}

export interface AddChangeDialogProps {
  selectedDate: Date;
  memoryOfVerseText: string;
  notes: string;
  onAddChangeDialogClosed: any;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
  changeType: any;
  changeNumber: string;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
}

export interface EditChangeDialogProps {
  selectedDate: Date;
  notes: string;
  onEditChangeDialogClosed: any;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
  changeType: any;
  changeNumber: string;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
}

export interface AddMemoryDialogProps {
  selectedDate: Date;
  memoryOfVerseText: string;
  notes: string;
  onAddMemoryDialogClosed: any;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
  changeNumber: string;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
}

export interface EditMemoryDialogProps {
  memoryID: string;
  selectedDate: Date;
  memoryOfVerseText: string;
  notes: string;
  onEditMemoryDialogClosed: any;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
  changeNumber: string;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
}

export interface ResidueImageProps {
  imageDate: Date;
  imageURL: string;
  imagePath: string;
  notes: string;
}

export interface AddResidueDialogProps {
  selectedDate: Date;
  notes: string;
  onAddResidueDialogClosed: any;
  memoryID: string;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
  bookNumber: number;
  chapterNumber: number;
  verseNumber: number;
}

export interface EditResidueDialogProps {
  selectedDate: Date;
  onEditResidueDialogClosed: any;
  residueID: string;
  notes: any;
  imageURL: any;
  imagePath: any;
}

export interface AddUserDialogProps {
  name: string;
  password: string;
  onAddUserDialogClosed: any;
  alertMessage: string;
  alertSeverity: any;
  showAlert: boolean;
}

// addMemory api outbound (post)
export interface AddMemoryAPI {
  ID: number;
  memoryDate: Date;
  restoredText: string;
  keywords: string;
  notes: string;
  upVotes: number;
  downVotes: number;
  book: number;
  chapter: number;
  verse: number;
  changeID: number;
}

export interface AddResidueAPI {
  imageDate: Date;
  imageURL: string;
  imagePath: string;
  notes: string;
}

export interface AddResidueLookupAPI {
  ID: number;
  residueImageID: number;
  memoryID: number;
}
