import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//import { FrontendMaterialTheme } from './Theme';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { changeTableTitleStyles } from './ChangeTableTitleStyles';
import { BCVSelect } from '../common/BCVSelect';
import { GetBookNames, GetChapterVerses, GetChaptersOfBook, GetDBMetrics } from '../../common/Api';
import { ChangesBCVSelectorProps } from './types';

export interface TitleSummaryProps {
  memoryCount: number;
  changeCount: number;
  imageCount: number;
  urlCount: number;
  videoCount: number;
  titleText: string;
  bcvSelectorChanged: any;
  data: any;
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#E0E0E0',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
});

const GetBookNumber = (bookNames: string[], name: string) => {
  let b = 1;
  for (let i = 0; i < bookNames.length; i++) {
    if (name.toLowerCase() === bookNames[i].toLowerCase()) {
      b = i + 1;
      break;
    }
  }
  return b;
};

export const ChangeTableTitle = (props: { data: TitleSummaryProps }) => {
  const classes = changeTableTitleStyles();

  const changesCountText = `Changes: ${props.data.changeCount}`;
  const memoryCountText = `Memories: ${props.data.memoryCount}`;
  const imageCountText = `Images: ${props.data.imageCount}`;

  const onChangeBook = async (event: any) => {
    if (event) {
      const bookName = event.target.textContent;
      bcvSettings.book = bookName;
      const b = GetBookNumber(bcvSettings.bookNames, bookName);
      bcvSettings.bookNumber = b;
      bcvSettings.inputBookValue = bookName;
      await GetChaptersOfBook(b).then((response) => {
        bcvSettings.bookMaxChapters = response.chapters;
      });
      await GetChapterVerses(b, 1).then((response) => {
        bcvSettings.chapterMaxVerses = response.verses;
      });
      setBCVSettings({ ...bcvSettings });
      // reset to chapter 1 to stay in range
      onChangeChapter(1);
    }
  };
  const onChangeChapter = async (value: any) => {
    if (value > 0) {
      bcvSettings.chapter = value;
      await GetChapterVerses(bcvSettings.bookNumber, value).then((response) => {
        bcvSettings.chapterMaxVerses = response.verses;
      });
      setBCVSettings({ ...bcvSettings });
      // not showing verse in change table, need to call here
      props.data.bcvSelectorChanged(bcvSettings.bookNumber, bcvSettings.chapter, bcvSettings.verse);
      // reset to verse 1 to stay in range
      //onChangeVerse(1);
    }
  };
  const onChangeVerse = async (value: any) => {
    if (value > 0) {
      bcvSettings.verse = value;
      setBCVSettings({ ...bcvSettings });
      // callback to changeTable
      props.data.bcvSelectorChanged(bcvSettings.bookNumber, bcvSettings.chapter, bcvSettings.verse);
    }
  };

  const bcvSelectorProps: ChangesBCVSelectorProps = {
    book: 'Genesis',
    bookNames: [],
    bookNumber: 1,
    chapter: 1,
    verse: 1,
    inputBookValue: 'Genesis',
    chapterMaxVerses: 31,
    bookMaxChapters: 50,
    onChangeChapter: onChangeChapter,
    onChangeVerse: onChangeVerse,
    onChangeBook: onChangeBook,
  };

  const [bcvSettings, setBCVSettings] = useState<ChangesBCVSelectorProps>(bcvSelectorProps);
  const [isDown, setIsDown] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function sleep(ms: any) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const CheckServerAlive = async () => {
      let keepChecking = true;
      while (keepChecking) {
        const dbMetrics = await GetDBMetrics();
        if (dbMetrics) {
          //console.log("CT: Server is up!");
          keepChecking = false;
          setIsDown(false);
          setIsLoading(true);
        }
        await sleep(5000);
      }
    };
    if (isDown) {
      CheckServerAlive();
    }
  }, [isDown, isLoading]);

  const fetchData = async () => {
    if (bcvSettings.bookNames.length === 0) {
      const bookNameData = await GetBookNames();
      bcvSettings.bookNames = bookNameData.names;
    }
    // need to get maxChapters and maxVerse of current chapter
    await GetChapterVerses(bcvSettings.bookNumber, bcvSettings.chapter).then((response) => {
      bcvSettings.chapterMaxVerses = response.verses;
    });
    await GetChaptersOfBook(bcvSettings.bookNumber).then((response) => {
      bcvSettings.bookMaxChapters = response.chapters;
    });
    setIsLoading(false);
    onChangeVerse(bcvSettings.verse);
    setBCVSettings({ ...bcvSettings });
  };
  useEffect(() => {
    if (bcvSettings.bookNames.length === 0) {
      fetchData();
    }
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container spacing={4}>
        <Grid item>
          <Typography className={classes.title} color='primary'>
            {props.data.titleText}
          </Typography>
        </Grid>
        <Grid item>
          <Chip className={classes.chip} color='primary' size='medium' label={changesCountText} />
          <Chip className={classes.chip} color='primary' size='medium' label={memoryCountText} />
          <Chip className={classes.chip} color='primary' size='medium' label={imageCountText} />
        </Grid>
        <Grid item>
          <BCVSelect verseSelectionDisabled={true} editorSettings={bcvSettings} setEditorSettings={setBCVSettings} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
