import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { adminMaterialTheme } from './Theme';
import { ResidueTable } from './ResidueTable';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import ImageIcon from '@mui/icons-material/Image';

interface MemoryTableRowData {
  ID: any;
  memoryDate: any;
  restoredText: any;
  notes: any;
}

export function MemoryTable(props: any) {
  const [selectedRow, setSelectedRow] = React.useState<MemoryTableRowData>(props.memorySelected);

  const handleRowClick = async (event: any, aSelectedRow: any) => {
    setSelectedRow(aSelectedRow);
    props.handleMemorySelected(aSelectedRow);
  };

  const detailPanel = [
    (rowData: any) => ({
      icon: () => {
        return <PhotoLibraryOutlinedIcon fontSize='large' color='inherit' />;
      },
      openIcon: () => {
        if (!rowData.expandable) {
          return <NoteOutlinedIcon fontSize='large' color='primary' />;
        }
        if (!rowData.hasMemories) {
          return <ImageIcon fontSize='large' color='action' />;
        }
        return <PhotoLibraryOutlinedIcon fontSize='large' color='primary' />;
      },
      tooltip: 'Show Residue Images',
      render: () => {
        return (
          <ResidueTable
            bookNumber={props.bookNumber}
            chapterNumber={props.chapterNumber}
            verseNumber={props.verseNumber}
            id={rowData.ID}
            memoryData={props.memoryData}
          />
        );
      },
    }),
  ];

  return (
    <ThemeProvider theme={adminMaterialTheme}>
      <MaterialTable
        onRowClick={handleRowClick}
        options={{
          padding: 'dense',
          paging: false,
          toolbar: false,
          search: false,
          headerStyle: {
            backgroundColor: '#607d8b',
            color: '#FFFFFF',
          },
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData ? 'palegreen' : '#FFF',
          }),
        }}
        columns={[
          {
            title: 'ID',
            field: 'ID',
            width: 'auto',
          },
          {
            title: 'Memory Date',
            field: 'memoryDate',
            width: 'auto',
          },
          {
            title: 'Memory of Verse',
            field: 'restoredText',
            width: 'auto',
          },
          {
            title: 'Notes',
            field: 'notes',
            width: 'auto',
          },
        ]}
        data={props.memoryData}
        detailPanel={detailPanel}
      />
    </ThemeProvider>
  );
}
