import React, { Component } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'center',
      fontSize: 16,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal',
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
  })
);

const Styled = ({ props }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='sm'>
        <Paper className='footer'>
          <Link rel='noopener' target='_blank' href='https://www.thesupernaturalbiblechanges.com'>
            Brought to you by https://www.thesupernaturalbiblechanges.com
          </Link>
        </Paper>
      </Container>
    </div>
  );
};

class CTA extends Component {
  render() {
    return <Styled />;
  }
}

export default CTA;
