/**
 * Returns a change from the cache with matching ID
 *
 * @param id ID of a change
 */
export const GetCachedChangedDataItem = (changeCache: any, id: string) => {
  if (changeCache && changeCache.length > 0) {
    const checkID = parseInt(id, 10);
    for (let i = 0; i < changeCache.length; i++) {
      if (changeCache[i].ID === checkID) {
        return i;
      }
    }
  }
  return -1;
};

/**
 * Returns a memory from the cache with matching ID
 *
 * @param id ID of a memory
 */
export const GetCachedMemoryDataItem = (memoryCache: any, id: string) => {
  if (memoryCache && memoryCache.length > 0) {
    const checkID = parseInt(id, 10);
    for (let i = 0; i < memoryCache.length; i++) {
      if (memoryCache[i].ID === checkID) {
        return i;
      }
    }
  }
  return -1;
};

/**
 * Gets number of book by name
 * @param name Name of Book
 */
export const GetBookNumber = (bookNames: string[], name: string) => {
  let b = 1;
  for (let i = 0; i < bookNames.length; i++) {
    if (name.toLowerCase() === bookNames[i].toLowerCase()) {
      b = i + 1;
      break;
    }
  }
  return b;
};

export function Delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
