import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const LoginDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    message: {
      alignContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(1, 0, 1),
    },
  })
);

export { LoginDialogStyles };
