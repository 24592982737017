import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { adminMaterialTheme } from '../Theme';
import { GetUsers, AddUser, DeleteUserByID, UpdateUser } from '../../../common/Auth';
import sha256 from 'crypto-js/sha256';
import { UserRoles } from '../../../common/Types';
/*
Username  string `json:"username" gorm:"not null"`
Password  string `json:"password" gorm:"not null"`
Notes     string `json:"notes"`
Enabled   bool   `json:"enabled" gorm:"not null;default:true"`
Role      Role   `json:"role" gorm:"FOREIGN_KEY"`
SessionID uint   `json:"sessionID" gorm:"FOREIGN_KEY"`
}

// Role ...
type Role struct {
gorm.Model
Name    string `json:"name" gorm:"not null"`
Level   uint64 `json:"level"`
Enabled bool   `json:"enabled" gorm:"not null;default:true"`
*/

interface UserData {
  ID: string;
  username: string;
  password: string;
  enabled: boolean;
  role: string;
  notes: string;
}

function convertResponseToUsers(response: any): UserData[] {
  var allUsers: UserData[] = [];
  for (let i in response) {
    const newItem = convertToUser(response[i]);
    allUsers.push(newItem);
  }
  return allUsers;
}

function convertToUser(data: any): UserData {
  const converted: UserData = {
    ID: data.ID,
    username: data.username,
    password: data.password,
    enabled: data.enabled,
    role: data.role,
    notes: data.notes,
  };
  return converted;
}
export const Manager = (props: any) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([] as UserData[]);
  const [selectedRow, setSelectedRow] = React.useState<UserData>();

  const fetchData = async () => {
    await GetUsers().then((response) => {
      if (response.length > 0) {
        const allUsers = convertResponseToUsers(response);
        setData(allUsers);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const onSave = async (data: any) => {
    if (typeof data.notes === 'undefined') {
      data.notes = '';
    }
    if (typeof data.role === 'undefined') {
      data.role = UserRoles.Contributor.Name;
    }
    if (data.role === '') {
      data.role = UserRoles.Contributor.Name;
    }
    if (typeof data.enabled === 'undefined') {
      data.enabled = false;
    }
    const payload = {
      username: data.username,
      password: sha256(data.password).toString(),
      notes: data.notes,
      enabled: data.enabled,
      role: data.role.toString(),
    };
    const result = await AddUser(payload);
    if (result.status !== 'OK') {
      return;
    }
    // refresh
    setIsLoading(true);
  };

  const onDelete = async (data: any) => {
    const payload = {
      userID: data.ID,
    };
    const result = await DeleteUserByID(payload);
    if (result.status !== 'OK') {
      return;
    }
    // refresh
    setIsLoading(true);
  };

  const onUpdate = async (data: any, old: any) => {
    // always store password as the sha256, never the real thing
    if (data.password !== old.password) {
      data.password = sha256(data.password).toString();
    }
    const payload = {
      ID: data.ID,
      username: data.username,
      password: data.password,
      notes: data.notes,
      enabled: data.enabled,
      role: data.role.toString(),
    };
    const result = await UpdateUser(payload);
    if (result.status !== 'OK') {
      return;
    }
    // refresh
    setIsLoading(true);
  };

  const handleRowClick = async (event: any, aSelectedRow: any) => {
    setSelectedRow(aSelectedRow);
  };

  return (
    <ThemeProvider theme={adminMaterialTheme}>
      <MaterialTable
        title='Users'
        columns={[
          { title: 'ID', field: 'ID', width: 'auto', editable: 'never' },
          { title: 'Name', field: 'username', width: 'auto' },
          { title: 'Password (SHA256)', field: 'password', width: 'auto', render: (rowData) => <>******</> },
          { title: 'Enabled', field: 'enabled', type: 'boolean', width: 'auto' },
          {
            title: 'Role',
            field: 'role',
            width: 'auto',
            initialEditValue: 2,
            lookup: {
              0: UserRoles.Admin.Name,
              1: UserRoles.Editor.Name,
              2: UserRoles.Contributor.Name,
            },
          },
          { title: 'Notes', field: 'notes', width: 'auto' },
        ]}
        data={data}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        options={{
          selection: true,
          paginationPosition: 'both',
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData ? 'palegreen' : '#FFF',
          }),
        }}
        editable={{
          onRowAdd: async (newData) => {
            return await onSave(newData);
          },
          onRowUpdate: async (newData, oldData) => {
            return await onUpdate(newData, oldData);
          },
          onRowDelete: async (oldData) => {
            return await onDelete(oldData);
          },
        }}
      />
    </ThemeProvider>
  );
};

export default Manager;
