import { makeStyles } from '@mui/styles';

export const bibleReaderStyles = makeStyles(() => ({
  searchFieldStyle: {
    minWidth: 400,
  },
  displayNone: {
    display: 'none',
  },
  contextViewer: {
    fontSize: '14px',
    textAlign: 'center',
  },
  contextViewerText: {
    fontSize: '14px',
    textAlign: 'center',
  },
  contextViewerTitle: {
    fontSize: '20px',
    textAlign: 'center',
  },
}));
