import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { FrontendMaterialTheme } from './Theme';
import { GetChangeByID, GetMemoriesWithResidue } from '../../common/Api';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import HealthCheck from './HealthCheck';
import { Helmet } from 'react-helmet';
import { FormatMemory, FormatOriginal } from './Utils';
import blueGrey from '@mui/material/colors/blueGrey';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';

export interface ChangeDetailProps {
  viewChange?: number;
}

export const ChangeDetail = (props: ChangeDetailProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDown, setIsDown] = React.useState(false);
  const [changeData, setChangeData] = useState<any>({});
  //const [memoryWithResidueData, setMemoryWithResidueData] = React.useState([]);
  const [memoryOfVerseFormatted, setMemoryOfVerseFormatted] = React.useState<JSX.Element | undefined>(undefined);
  const [currentVerseFormatted, setCurrentVerseFormatted] = React.useState<JSX.Element | undefined>(undefined);
  let viewChange = 1;
  const parentParams = useParams();
  if (parentParams.id) {
    viewChange = parseInt(parentParams.id, 10);
    if (isNaN(viewChange)) {
      console.log('invalid change id, setting to 1');
      viewChange = 1;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetChangeByID(viewChange);
      if (typeof response === 'undefined') {
        console.log('server not responding');
        setIsDown(true);
        return;
      }
      // get memories of change
      const memoryResponse = await GetMemoriesWithResidue(viewChange.toString());
      if (typeof memoryResponse === 'undefined') {
        console.log('server not responding');
        setIsDown(true);
        return;
      }
      // for memories without residue images, add the stock photo as a placeholder
      for (let i = 0; i < memoryResponse.memories.length; i++) {
        if (memoryResponse.memories[i].residueImages === null) {
          memoryResponse.memories[i].residueImages = [
            {
              imagePath: 'img/bible_changes.jpg',
              imageURL: 'img/bible_changes.jpg',
              imageDate: memoryResponse.memories[i].memoryDate,
              notes: memoryResponse.memories[i].notes,
            },
          ];
        }
      }
      if (response?.memorySummary?.restoredText) {
        const a = FormatMemory(response.currentVerse, response?.memorySummary?.restoredText);
        const b = FormatOriginal(response.currentVerse, response?.memorySummary?.restoredText);
        setMemoryOfVerseFormatted(b);
        setCurrentVerseFormatted(a);
      } else {
        setCurrentVerseFormatted(response.currentVerse);
        setMemoryOfVerseFormatted(
          <Box sx={{ color: 'green !' }}>Note: No memory of this verse has been submitted.</Box>
        );
      }
      if (response.notes === '') {
        // check memory response
        response.notes = response?.memorySummary?.notes || '';
      }
      // get
      setIsDown(false);
      setChangeData(response);
      setIsLoading(false);
    };
    if (isLoading) {
      fetchData();
    }
  }, [isLoading, viewChange]);

  const title = `Scribe: View ${changeData.BCV} Changes and Memories`;
  const description = `View current verse and memories. Current Verse: ${changeData.currentVerse}`;
  //const urlToChange = `https://search.thesupernaturalbiblechanges.com/changes/${props.viewChange}`;
  const urlToChange = `${window.location.origin}/changes/${viewChange}`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={urlToChange} />
        <meta property='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:image' content='/img/bible_changes.jpg' />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={FrontendMaterialTheme}>
          {isLoading && <HealthCheck isDown={isDown} />}
          <Card sx={{ minWidth: 275 }}>
            <CardMedia
              sx={{ height: 140 }}
              image='/img/FB_YO_Creation_JPEGW/08_FB_YO_Creation_1920_cropped.jpg'
              title='Genesis'
            />
            <CardContent>
              <Typography
                sx={{
                  fontSize: 'x-large',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                gutterBottom>
                Summary for {changeData.BCV}
              </Typography>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs>
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'x-large' }} gutterBottom variant='caption'>
                      Memory of Verse
                    </Typography>
                    <Typography sx={{ fontSize: 'x-large' }} color='primary' gutterBottom>
                      {memoryOfVerseFormatted}
                    </Typography>
                  </Paper>
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'x-large' }} gutterBottom variant='caption'>
                      Current Verse
                    </Typography>
                    <Typography sx={{ fontSize: 'x-large' }} color='primary' gutterBottom>
                      {currentVerseFormatted}
                    </Typography>
                  </Paper>
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'large' }} color='info' gutterBottom variant='caption'>
                      Detected (approximately): {changeData.detectedAt}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'x-large' }} gutterBottom variant='caption'>
                      Notes
                    </Typography>
                    <Typography sx={{ fontSize: 'x-large' }} color='primary' gutterBottom>
                      {changeData.notes}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', backgroundColor: blueGrey.A400 }}>
              <Paper variant='elevation' sx={{ color: 'blue' }}>
                <Button variant='contained' size='medium' href={urlToChange}>
                  More Details
                </Button>
              </Paper>
            </CardActions>
          </Card>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

/*
            {changeData?.currentVerse}
            {changeData?.memorySummary?.restoredText}
*/
