import * as env from 'env-var';
import { COOKIE_NAME } from './Types';

let urlPrefix = '';
let isDevMode = false;
if (env.get('NODE_ENV').default('production').asString() === 'development') {
  isDevMode = true;
}
if (isDevMode) {
  urlPrefix = `http://localhost:7777`;
}

export const CallAPIWithAuth = async (url: string, payload: any) => {
  const token = GetTokenFromCookie();
  if (token == null) {
    return '';
  }
  return await fetch(url, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    redirect: 'follow',
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(console.log);
};

/*
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
*/
export const CallAPIWithoutAuth = async (url: string, payload: any) => {
  return await fetch(url, {
    method: 'post',
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(console.log);
};

export const CallAPIMethodGet = async (url: string) => {
  return await fetch(url, {
    method: 'get',
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(console.log);
};

export const GetTokenFromCookie = (): string | null => {
  const name = COOKIE_NAME;
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

export const GetAllChanges = async () => {
  const url = `${urlPrefix}/v1/GetAllChanges`;
  return await CallAPIWithoutAuth(url, {});
};

export const GetChangesByRange = async (pageNumber: number, perPage: number) => {
  const url = `${urlPrefix}/v1/GetChangesByRange?per_page=${perPage}&page=${pageNumber}`;
  return await CallAPIMethodGet(url);
};

/**
 * Will return an offset of changes that includes the specified ID, or just the beginning if the id is not found
 *
 * @param   {number}  pageNumber  Current Page Number
 * @param   {number}  perPage     Items per page
 * @param   {number}  changeID    Change to locate
 *
 * @return  {[type]}              Table Data
 */
export const GetChangesByRangeIncludingID = async (pageNumber: number, perPage: number, changeID: number) => {
  const url = `${urlPrefix}/v1/GetChangesByRangeIncludingID?per_page=${perPage}&page=${pageNumber}&change_id=${changeID}`;
  return await CallAPIMethodGet(url);
};

export const GetChangesByRangeStartingAtBCV = async (
  pageNumber: number,
  perPage: number,
  book: number,
  chapter: number,
  verse: number
) => {
  const url = `${urlPrefix}/v1/GetChangesByRangeStartingAtBCV?per_page=${perPage}&page=${pageNumber}&book=${book}&chapter=${chapter}&verse=${verse}`;
  return await CallAPIMethodGet(url);
};

export const GetChangesByRangeWithSearch = async (pageNumber: number, perPage: number, searchText: string) => {
  const encodedText = encodeURIComponent(searchText);
  const url = `${urlPrefix}/v1/GetChangesByRangeWithSearch?per_page=${perPage}&page=${pageNumber}&search=${encodedText}`;
  return await CallAPIMethodGet(url);
};

export const GetChangeIDs = async (book: number, chapter: number, verse: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
    verseNumber: verse,
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetChangesByVerse`, payload)
    .then((data) => {
      // just need the .ID
      let changes = [];
      for (let i in data) {
        changes.push(data[i].ID.toString());
      }
      return changes;
    })
    .catch(console.log);
};

export const GetChanges = async (book: number, chapter: number, verse: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
    verseNumber: verse,
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetChangesByVerse`, payload);
};

export const GetChangesByChapter = async (book: number, chapter: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetChangesByChapter`, payload);
};

export const GetChangeByID = async (id: number) => {
  const payload = {
    id: id,
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetChangeByID`, payload);
};

export const GetMemories = async (changeID: string) => {
  const payload = {
    changeNumber: parseInt(changeID, 10),
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetMemoriesOfChange`, payload)
    .then((data) => {
      // just need the .ID
      let memories = [];
      for (let i in data) {
        memories.push(data[i]);
      }
      return { memories } as any;
    })
    .catch(console.log);
};

export const GetMemoriesWithResidue = async (changeID: string) => {
  const payload = {
    changeNumber: parseInt(changeID, 10),
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetMemoriesOfChangeWithResidue`, payload)
    .then((data) => {
      // just need the .ID
      let memories = [];
      for (let i in data) {
        memories.push(data[i]);
      }
      return { memories } as any;
    })
    .catch(console.log);
};

export const GetMemoryIDs = async (changeID: string) => {
  const payload = {
    changeNumber: parseInt(changeID, 10),
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetMemoriesOfChange`, payload)
    .then((data) => {
      // just need the .ID
      let memories = [];
      for (let i in data) {
        memories.push(data[i].ID.toString());
      }
      return { memories } as any;
    })
    .catch(console.log);
};

export const GetBookNames = async () => {
  const url = `${urlPrefix}/v1/GetBookNamesAsList`;
  return await CallAPIWithoutAuth(url, {});
};

export const GetVerseText = async (book: number, chapter: number, verse: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
    verseNumber: verse,
  };
  const url = `${urlPrefix}/v1/GetVerseText`;
  return await CallAPIWithoutAuth(url, payload);
};

export const GetChapterText = async (book: number, chapter: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
  };
  const url = `${urlPrefix}/v1/GetChapterText`;
  return await CallAPIWithoutAuth(url, payload);
};

export const GetChapterTextWithChanges = async (book: number, chapter: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
  };
  const url = `${urlPrefix}/v1/GetChapterTextWithChanges`;
  return await CallAPIWithoutAuth(url, payload);
};

export const GetChapterVerses = async (book: number, chapter: number) => {
  const payload = {
    bookNumber: book,
    chapterNumber: chapter,
  };
  const url = `${urlPrefix}/v1/GetChapterVerses`;
  return await CallAPIWithoutAuth(url, payload);
};

export const GetChaptersOfBook = async (book: number) => {
  const payload = {
    bookNumber: book,
  };
  const url = `${urlPrefix}/v1/GetChaptersOfBook`;
  return await CallAPIWithoutAuth(url, payload);
};

export const GetDBMetrics = async () => {
  const url = `${urlPrefix}/v1/GetDBMetrics`;
  return await CallAPIWithoutAuth(url, {});
};

export const Search = async (searchText: string) => {
  const payload = {
    search: searchText,
  };
  const url = `${urlPrefix}/v1/Search`;
  return await CallAPIWithoutAuth(url, payload);
};

// TODO: the abortsignal is not working correctly, disabled for now.  this may cause warnings in console output
// currently it will force all residue being displayed to be emptied
export const GetResidueOfMemory = async (id: string, signal: AbortSignal) => {
  const payload = {
    memoryID: parseInt(id, 10),
  };
  return await CallAPIWithoutAuth(`${urlPrefix}/v1/GetResidueOfMemory`, payload)
    .then((data) => {
      let items = [];
      for (let i in data) {
        items.push(data[i]);
      }
      return { residue: items } as any;
    })
    .catch((reason) => {
      console.log(reason);
    });
};

export const AddChange = async (payload: any) => {
  const url = `${urlPrefix}/v1/AddChange`;
  return await CallAPIWithAuth(url, payload);
};

export const UpdateChange = async (payload: any) => {
  const url = `${urlPrefix}/v1/UpdateChange`;
  return await CallAPIWithAuth(url, payload);
};

export const DeleteChange = async (payload: any) => {
  const url = `${urlPrefix}/v1/DeleteChange`;
  return await CallAPIWithAuth(url, payload);
};

export const AddMemory = async (payload: any) => {
  const url = `${urlPrefix}/v1/AddMemory`;
  return await CallAPIWithAuth(url, payload);
};

export const UpdateMemory = async (payload: any) => {
  const url = `${urlPrefix}/v1/UpdateMemory`;
  return await CallAPIWithAuth(url, payload);
};

export const DeleteMemory = async (payload: any) => {
  const url = `${urlPrefix}/v1/DeleteMemory`;
  return await CallAPIWithAuth(url, payload);
};

export const AddResidue = async (payload: any) => {
  const url = `${urlPrefix}/v1/AddResidue`;
  return await CallAPIWithAuth(url, payload);
};

export const UpdateResidue = async (payload: any) => {
  const url = `${urlPrefix}/v1/UpdateResidue`;
  return await CallAPIWithAuth(url, payload);
};

export const ResidueFileUpload = async (payload: FormData) => {
  const token = GetTokenFromCookie();
  if (token == null) {
    return '';
  }
  return await fetch(`${urlPrefix}/v1/ResidueFileUpload`, {
    method: 'post',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    redirect: 'follow',
    body: payload,
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(console.log);
};

export const AddResidueLookup = async (payload: any) => {
  const url = `${urlPrefix}/v1/AddResidueLookup`;
  return await CallAPIWithAuth(url, payload);
};

export const DeleteResidue = async (payload: any) => {
  const url = `${urlPrefix}/v1/DeleteResidue`;
  return await CallAPIWithAuth(url, payload);
};

export const DeleteResidueLookup = async (payload: any) => {
  const url = `${urlPrefix}/v1/DeleteResidueLookup`;
  return await CallAPIWithAuth(url, payload);
};
