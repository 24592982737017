import React from 'react';
import { makeStyles } from '@mui/styles';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface FileUploaderProps {
  progress: number;
  messages: string[];
}

export default function LinearWithValueLabel(props: FileUploaderProps) {
  const classes = useStyles();
  const [displayedProgress, setDisplayedProgress] = React.useState(props.progress);

  React.useEffect(() => {
    if (displayedProgress !== props.progress) {
      setDisplayedProgress(props.progress);
    }
  }, [props.progress, displayedProgress]);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={displayedProgress} />
      <Alert severity='info'>
        <AlertTitle>Messages</AlertTitle>
        <List dense={true}>
          {props.messages.map((message) => {
            return (
              <ListItem key={Math.random()}>
                <ListItemText primary={message} />
              </ListItem>
            );
          })}
        </List>
      </Alert>
    </div>
  );
}
