import { makeStyles } from '@mui/styles';

const changeTableTitleStyles = makeStyles(() => ({
  chip: {
    marginBottom: '2px',
    marginTop: '12px',
  },
  bullet: {
    display: 'inline-block',
    margin: '2px 2px 2px 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginLeft: '10px',
    marginTop: '12px',
    textAlign: 'left',
    fontSize: '22px !important',
    fontWeight: 'bold',
    color: 'white',
  },
}));

export { changeTableTitleStyles };
