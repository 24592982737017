import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/styles';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import blueGrey from '@mui/material/colors/blueGrey';

import './index.css';
import App from './App';
import Layout from './Layout';
import * as serviceWorker from './serviceWorker';
import { ChangeDetail } from './components/frontend/ChangeDetail';

const defaultMaterialTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 'bold',
          backgroundColor: 'yellow',
          margin: '10px',
          '&:hover': {
            backgroundColor: 'green',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      primary: blueGrey,
      secondary: blueGrey,
    },
  })
);

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
  <ThemeProvider theme={defaultMaterialTheme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<App view='about' />} />
          <Route path='about' element={<App view='about' />} />
          <Route path='reader' element={<App view='reader' />} />
          <Route path='changes' element={<App view='changes' />}>
            <Route path=':changesId' element={<App view='changes' />} />
          </Route>
          <Route path='changeDetail/:id' element={<ChangeDetail />} />
          <Route path='link/:id' element={<ChangeDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
