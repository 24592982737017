import { adaptV4Theme, createTheme } from '@mui/material/styles';

export const adminMaterialTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTableCell: {
        root: {
          padding: '10px',
        },
      },
      MuiTypography: {
        root: {
          fontSize: 'inherit !important',
        },
      },
      MuiIconButton: {
        root: {
          backgroundColor: 'inherit !important',
        },
      },
      MuiButton: {
        root: {
          padding: '0px',
          fontWeight: 'bold',
          backgroundColor: 'inherit !important',
          margin: '10px',
          '&:hover': {
            backgroundColor: 'green',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#ff9100',
      },
    },
  })
);

export const adminMenuTheme = createTheme({
  palette: {
    primary: {
      main: '#607d8b',
    },
    secondary: {
      main: '#01579b',
    },
  },
});
