import { ThemeProvider } from '@mui/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { FrontendMaterialTheme } from '../Theme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Helmet } from 'react-helmet';
import blueGrey from '@mui/material/colors/blueGrey';

export interface AboutProps {}

export const About = (props: AboutProps) => {
  const title = `Scribe: About`;
  const description = `What this project is all about!`;
  const urlToAbout = `${window.location.origin}/about`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={urlToAbout} />
        <meta property='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:image' content='/img/bible_changes.jpg' />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={FrontendMaterialTheme}>
          <Card sx={{ minWidth: 275 }}>
            <CardMedia
              sx={{ height: 140 }}
              image='/img/FB_YO_Creation_JPEGW/08_FB_YO_Creation_1920_cropped.jpg'
              title='Genesis'
            />
            <CardContent>
              <Typography
                sx={{
                  fontSize: 'x-large',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                gutterBottom>
                About this Site
              </Typography>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs>
                  <Paper
                    sx={{
                      padding: FrontendMaterialTheme.spacing(2),
                      textAlign: 'center',
                      color: FrontendMaterialTheme.palette.text.secondary,
                    }}>
                    <Typography sx={{ fontSize: 'x-large' }} gutterBottom variant='caption'>
                      This is intended to provide memories about verses that have been changed.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', backgroundColor: blueGrey.A400 }}>
              <Paper variant='elevation' sx={{ color: 'blue' }}>
                <Button variant='contained' size='medium' href={'/changes'}>
                  View Changes
                </Button>
              </Paper>
              <Paper variant='elevation' sx={{ color: 'blue' }}>
                <Button variant='contained' size='medium' href={'/reader'}>
                  View Reader
                </Button>
              </Paper>
            </CardActions>
          </Card>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
