import React, { Component, useState } from 'react';
import * as env from 'env-var';

import { ThemeProvider } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';

import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';

import TextField from '@mui/material/TextField';
import { backendMaterialTheme, backendStyles } from './Styles';
import { MemoryEditorDatePicker } from './MemoryEditorDatePicker';
import { MemoryEditorBCVSelect } from './MemoryEditorBCVSelect';
import { MemoryProps } from './types';
import {
  GetChangeIDs,
  GetChanges,
  GetBookNames,
  GetVerseText,
  GetMemories,
  GetMemoryIDs,
  GetChaptersOfBook,
} from '../../common/Api';
import { GetCachedMemoryDataItem, GetBookNumber } from './Utils';

const Styled = ({ props }: any) => {
  let urlPrefix = '';
  let isDevMode = false;
  if (env.get('NODE_ENV').default('production').asString() === 'development') {
    isDevMode = true;
  }
  if (isDevMode) {
    urlPrefix = `http://localhost:7777`;
  }
  const classes = backendStyles();

  const onChangeBook = async (event: any) => {
    if (event) {
      const bookName = event.target.textContent;
      editorSettings.bookName = bookName;
      const b = GetBookNumber(editorSettings.bookNames, bookName);
      editorSettings.bookNumber = b;
      let response = await GetChaptersOfBook(editorSettings.bookNumber);
      editorSettings.bookMaxChapters = response.chapters;
      //editorSettings.inputBookValue = bookName;
      setEditorSettings({ ...editorSettings });
      // reset to chapter 1 to stay in range
      onChangeChapter(1);
    }
  };

  /**
   * Sets Chapter number
   * @param value Chapter Number
   */
  const onChangeChapter = (value: any) => {
    if (value > 0 && value !== editorSettings.chapter) {
      editorSettings.chapter = value;
      setEditorSettings({ ...editorSettings });
      // reset to verse 1 to stay in range
      onChangeVerse(1);
    }
  };

  /**
   * Loads Verse Text
   * @param value verse Number
   */
  const onVerseSelectChanged = async (verse: any) => {
    const response = await GetVerseText(editorSettings.bookNumber, editorSettings.chapter, verse);
    editorSettings.selectedVerseText = response.text;
    setEditorSettings({ ...editorSettings });
  };

  // TODO: refactor this
  /**
   * Sets Verse number
   * @param value Verse Number
   */
  const onChangeVerse = async (value: any) => {
    if (value > 0 && value !== editorSettings.verse) {
      editorSettings.verse = value;
      //setEditorSettings({ ...editorSettings });
      //onVerseSelectChanged(value);
      // load changes for this verse
      const changeIDs = await GetChangeIDs(editorSettings.bookNumber, editorSettings.chapter, value);
      setExistingChangeIDs(changeIDs);
      if (changeIDs && changeIDs.length > 0) {
        editorSettings.selectedChangeID = changeIDs[0];
        const changeData = await GetChanges(editorSettings.bookNumber, editorSettings.chapter, value);
        if (changeData) {
          editorSettings.selectedChange = changeData[0];
        }
        // update cache
        //setChangeCache(changeData.changes);
        const memoryIDs = await GetMemoryIDs(changeIDs[0]);
        if (memoryIDs.memories.length > 0) {
          editorSettings.selectedMemoryID = memoryIDs.memories[0];
          if (memoryIDs.memories[0]) {
            const memoryData = await GetMemories(memoryIDs.memories[0]);
            if (memoryData.memories.length > 0) {
              editorSettings.selectedMemory = memoryData.memories[0];
              editorSettings.selectedMemoryID = memoryData.memories[0].ID;
              editorSettings.inputExistingMemoryValue = memoryData.memories[0].ID;
              setExistingMemoryIDs(memoryIDs.memories);
              setMemoryCache(memoryData.memories);
            } else {
              editorSettings.selectedMemory = null;
              editorSettings.selectedMemoryID = '';
              editorSettings.inputExistingMemoryValue = '';
              setExistingMemoryIDs([]);
              setMemoryCache([]);
            }
          } else {
            editorSettings.selectedMemory = null;
            editorSettings.selectedMemoryID = '';
            editorSettings.inputExistingMemoryValue = '';
            setExistingMemoryIDs([]);
            setMemoryCache([]);
          }
        } else {
          editorSettings.selectedMemory = null;
          editorSettings.selectedMemoryID = '';
          editorSettings.inputExistingMemoryValue = '';
          setExistingMemoryIDs([]);
          setMemoryCache([]);
        }
      } else {
        editorSettings.selectedMemory = null;
        editorSettings.selectedMemoryID = '';
        editorSettings.inputExistingMemoryValue = '';
        editorSettings.selectedChangeID = '';
        editorSettings.selectedChange = null;
        setExistingMemoryIDs([]);
        // invalidate cache
        //setChangeCache([]);
        setMemoryCache([]);
      }
    }
    onVerseSelectChanged(value);
    setEditorSettings({ ...editorSettings });
  };

  const loadExistingMemory = async (value: any) => {
    // only update if value is different
    if (editorSettings.selectedMemoryID === value) {
      return;
    }
    editorSettings.selectedMemoryID = value;
    // need to set selectedChange to be the content of the cached item
    //console.log(memoryCache);
    let memoryCacheIndex = GetCachedMemoryDataItem(memoryCache, value);
    if (memoryCacheIndex >= 0) {
      editorSettings.selectedMemory = memoryCache[memoryCacheIndex];
    }
    //console.log('Load!');
    if (value && editorSettings.selectedMemory) {
      // populate the form
      editorSettings.memoryNumber = editorSettings.selectedMemory.ID.toString();
      editorSettings.selectedDate = editorSettings.selectedMemory.CreatedAt;
      editorSettings.text = editorSettings.selectedMemory.text;
      editorSettings.textURL = editorSettings.selectedMemory.textURL;
      editorSettings.textURLEnabled = editorSettings.selectedMemory.textURLEnabled;
      editorSettings.imageURL = editorSettings.selectedMemory.imageURL;
      editorSettings.imageURLEnabled = editorSettings.selectedMemory.imageURLEnabled;
      editorSettings.videoURL = editorSettings.selectedMemory.videoURL;
      editorSettings.videoEnabled = editorSettings.selectedMemory.videoEnabled;
      editorSettings.keywords = editorSettings.selectedMemory.keywords;
      editorSettings.fixesWordChanges = editorSettings.selectedMemory.fixesWordChanges;
      editorSettings.fixesPunctuationErrors = editorSettings.selectedMemory.fixesPunctuationErrors;
      editorSettings.fixesGrammarErrors = editorSettings.selectedMemory.fixesGrammarErrors;
      editorSettings.notes = editorSettings.selectedMemory.notes;
      editorSettings.upVotes = editorSettings.selectedMemory.upVotes;
      editorSettings.downVotes = editorSettings.selectedMemory.downVotes;
      if (editorSettings.selectedChange) {
        editorSettings.bookNumber = editorSettings.selectedChange.book || 1;
        editorSettings.chapter = editorSettings.selectedChange.chapter || 1;
        editorSettings.verse = editorSettings.selectedChange.verse || 1;
      }
    }
    setEditorSettings({ ...editorSettings });
  };

  const initProps: MemoryProps = {
    bookName: 'Genesis',
    bookNames: props.data,
    bookNumber: 1,
    bookMaxChapters: 50,
    inputBookValue: 'Genesis',
    chapter: 1,
    verse: 1,
    keywords: '',
    notes: '',
    imageURL: '',
    imageURLEnabled: false,
    text: '',
    textURL: '',
    textURLEnabled: false,
    videoURL: '',
    videoEnabled: false,
    fixesWordChanges: false,
    fixesPunctuationErrors: false,
    fixesGrammarErrors: false,
    memoryNumber: props.selectedMemoryID,
    selectedChangeID: props.selectedChangeID,
    selectedChange: props.selectedChange,
    inputExistingChangeValue: props.selectedChangeID,
    selectedDate: new Date(),
    selectedMemoryID: '',
    selectedMemory: null,
    inputExistingMemoryValue: props.selectedMemoryID,
    selectedVerseText: props.verseText,
    upVotes: 0,
    downVotes: 0,
    onChangeChapter: onChangeChapter,
    onChangeVerse: onChangeVerse,
    onChangeBook: onChangeBook,
  };

  const [editorSettings, setEditorSettings] = React.useState<MemoryProps>(initProps);
  const [existingChangeIDs, setExistingChangeIDs] = useState(props.changes);
  const [existingMemoryIDs, setExistingMemoryIDs] = useState(props.memories);

  // const [changeCache, setChangeCache] = useState(props.changeData);
  const [memoryCache, setMemoryCache] = useState(props.memoryData);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('info');

  // preload the first memory
  if (props.selectedMemoryID !== '') {
    loadExistingMemory(props.selectedMemoryID);
    props.selectedMemoryID = '';
  }

  /**
   * Saves Memory to database
   */
  const onSave = () => {
    // convert name of book to number
    const payload = {
      memoryDate: editorSettings.selectedDate,
      text: editorSettings.text,
      textURL: editorSettings.textURL,
      textURLEnabled: editorSettings.textURLEnabled,
      imageURL: editorSettings.imageURL,
      imageURLEnabled: editorSettings.imageURLEnabled,
      videoURL: editorSettings.videoURL,
      videoEnabled: editorSettings.videoEnabled,
      videoOffset: 0,
      keywords: editorSettings.keywords,
      fixesWordChanges: editorSettings.fixesWordChanges,
      fixesPunctuationErrors: editorSettings.fixesPunctuationErrors,
      fixesGrammarErrors: editorSettings.fixesGrammarErrors,
      notes: editorSettings.notes,
      upVotes: 0,
      downVotes: 0,
      book: editorSettings.bookNumber,
      chapter: editorSettings.chapter,
      verse: editorSettings.verse,
      changeID: parseInt(editorSettings.selectedChangeID, 10),
    };
    // post the memory, and return the ID with confirmation dialog
    if (payload) {
      fetch(`${urlPrefix}/AddMemory`, {
        method: 'post',
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data.id);
          //console.log(data.message);
          //console.log(data.status);
          if (data.hasOwnProperty('id')) {
            editorSettings.memoryNumber = data.id;
          }
          setEditorSettings({ ...editorSettings });
          // TODO: update cache!
          // set alert with status of save
          setAlertSeverity('info');
          setAlertMessage('Save Status: ' + data.message);
          setShowAlert(true);
        })
        .catch(console.log);
    }
  };

  /**
   * Resets all input dialog options to defaults
   */
  const onClear = async () => {
    editorSettings.memoryNumber = 'TBD';
    editorSettings.text = '';
    editorSettings.textURL = '';
    editorSettings.textURLEnabled = false;
    editorSettings.imageURL = '';
    editorSettings.imageURLEnabled = false;
    editorSettings.videoURL = '';
    editorSettings.videoEnabled = false;
    editorSettings.keywords = '';
    editorSettings.fixesWordChanges = false;
    editorSettings.fixesPunctuationErrors = false;
    editorSettings.fixesGrammarErrors = false;
    editorSettings.upVotes = 0;
    editorSettings.downVotes = 0;
    editorSettings.chapter = 1;
    editorSettings.verse = 1;
    editorSettings.keywords = '';
    editorSettings.notes = '';
    editorSettings.selectedDate = new Date();
    editorSettings.bookName = 'Genesis';
    editorSettings.bookNumber = 1;
    editorSettings.inputBookValue = 'Genesis';
    editorSettings.selectedMemoryID = '';
    editorSettings.selectedMemory = null;
    setEditorSettings({ ...editorSettings });
  };

  return (
    <form className={classes.root} noValidate autoComplete='on'>
      <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
        <MemoryEditorDatePicker editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
        <Tooltip title='Memory being edited or to be determined when new'>
          <Chip size='medium' label={'Memory Number:' + editorSettings.memoryNumber} />
        </Tooltip>
        <Autocomplete
          id='existingMemory'
          style={{ width: 150 }}
          value={editorSettings.selectedMemoryID}
          onChange={(event: any, newValue: string | null) => {
            loadExistingMemory(newValue);
          }}
          inputValue={editorSettings.inputExistingMemoryValue}
          onInputChange={(event, newInputValue) => {
            editorSettings.inputExistingMemoryValue = newInputValue;
            setEditorSettings({ ...editorSettings });
          }}
          options={existingMemoryIDs}
          renderInput={(params) => <TextField {...params} required label='Existing Memory' margin='dense' />}
        />
        <Autocomplete
          id='existingChange'
          style={{ width: 150 }}
          value={editorSettings.selectedChangeID}
          onChange={(event: any, newValue: string | null) => {
            if (newValue) {
              editorSettings.selectedChangeID = newValue;
              setEditorSettings({ ...editorSettings });
            }
          }}
          inputValue={editorSettings.inputExistingChangeValue}
          onInputChange={(event, newInputValue) => {
            editorSettings.inputExistingChangeValue = newInputValue;
            setEditorSettings({ ...editorSettings });
          }}
          options={existingChangeIDs}
          renderInput={(params) => <TextField {...params} required label='Select Change' margin='dense' />}
        />
      </Grid>
      <MemoryEditorBCVSelect editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
      <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
        <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
          <TextField
            style={{ width: 350 }}
            margin='dense'
            value={editorSettings.keywords}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorSettings.keywords = event.target.value;
              setEditorSettings({ ...editorSettings });
            }}
            id='keywords'
            label='keywords'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Paper variant='outlined' elevation={3} style={{ width: '100%' }}>
        <Grid container direction='column' style={{ width: '100%' }} alignItems='stretch'>
          <TextField
            style={{ width: '100%' }}
            margin='dense'
            value={editorSettings.text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorSettings.text = event.target.value;
              setEditorSettings({ ...editorSettings });
            }}
            id='text'
            label='text'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ width: '100%' }}
            margin='dense'
            value={editorSettings.textURL}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorSettings.textURL = event.target.value;
              setEditorSettings({ ...editorSettings });
            }}
            id='textURL'
            label='textURL'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            label='Text URL Enabled?'
            control={
              <Switch
                checked={editorSettings.textURLEnabled}
                onChange={(event) => {
                  editorSettings.textURLEnabled = event.target.checked;
                  setEditorSettings({ ...editorSettings });
                }}
                name='textURLEnabled'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
        </Grid>
      </Paper>
      <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          value={editorSettings.imageURL}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editorSettings.imageURL = event.target.value;
            setEditorSettings({ ...editorSettings });
          }}
          id='imageURL'
          label='imageURL'
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          label='Image URL Enabled?'
          control={
            <Switch
              checked={editorSettings.imageURLEnabled}
              onChange={(event) => {
                editorSettings.imageURLEnabled = event.target.checked;
                setEditorSettings({ ...editorSettings });
              }}
              name='imageURLEnabled'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
        />
      </Grid>

      <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          value={editorSettings.videoURL}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editorSettings.videoURL = event.target.value;
            setEditorSettings({ ...editorSettings });
          }}
          id='videoURL'
          label='videoURL'
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          label='Video Enabled?'
          control={
            <Switch
              checked={editorSettings.videoEnabled}
              onChange={(event) => {
                editorSettings.videoEnabled = event.target.checked;
                setEditorSettings({ ...editorSettings });
              }}
              name='videoURLEnabled'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
        />
      </Grid>

      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <FormControlLabel
          label='Fixes Word Changes?'
          control={
            <Switch
              checked={editorSettings.fixesWordChanges}
              onChange={(event) => {
                editorSettings.fixesWordChanges = event.target.checked;
                setEditorSettings({ ...editorSettings });
              }}
              name='fixesWordChanges'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
        />
        <FormControlLabel
          label='Fixes Punctuation Errors?'
          control={
            <Switch
              checked={editorSettings.fixesPunctuationErrors}
              onChange={(event) => {
                editorSettings.fixesPunctuationErrors = event.target.checked;
                setEditorSettings({ ...editorSettings });
              }}
              name='fixesPunctuationErrors'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
        />
        <FormControlLabel
          label='Fixes Grammar Errors?'
          control={
            <Switch
              checked={editorSettings.fixesGrammarErrors}
              onChange={(event) => {
                editorSettings.fixesGrammarErrors = event.target.checked;
                setEditorSettings({ ...editorSettings });
              }}
              name='fixesGrammarErrors'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
        />
      </Grid>

      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <TextField
          style={{ width: '100%' }}
          id='outlined-multiline-static'
          label='Notes'
          value={editorSettings.notes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            editorSettings.notes = event.target.value;
            setEditorSettings({ ...editorSettings });
          }}
          multiline
          rows='8'
          variant='outlined'
        />
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave();
          }}>
          Save
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<ClearAllIcon />}
          onClick={() => {
            onClear();
          }}>
          New
        </Button>
      </Grid>
      {showAlert && (
        <Alert variant='filled' severity={alertSeverity}>
          {alertMessage}
        </Alert>
      )}
    </form>
  );
};

type MemoryEditorProps = {
  data: any;
};

class MemoryEditor extends Component<MemoryEditorProps> {
  state: any;
  urlPrefix = '';

  constructor(props: any) {
    super(props);
    let isDevMode = false;
    if (env.get('NODE_ENV').default('production').asString() === 'development') {
      isDevMode = true;
    }
    if (isDevMode) {
      this.urlPrefix = `http://localhost:7777`;
    }

    this.state = {
      data: [{}],
      changes: [{}],
      changeData: [{}],
      memories: [{}],
      memoryData: [{}],
      verseText: '',
      selectedChange: null,
      selectedChangeID: '',
      selectedMemory: null,
      selectedMemoryID: '',
      isLoading: true,
    };
  }

  async initialize() {
    await GetBookNames().then((response) => {
      this.setState({
        data: response.names,
      });
    });

    await GetChangeIDs(1, 1, 1).then((response) => {
      let selectedChangeID = '';
      if (response && response.length > 0) {
        selectedChangeID = response[0];
      }
      this.setState({
        changes: response,
        selectedChangeID: selectedChangeID,
      });
    });

    await GetChanges(1, 1, 1).then((response) => {
      let selectedChange = null;
      if (response && response.length > 0) {
        selectedChange = response[0];
        this.setState({
          changeData: response,
          selectedChange: selectedChange,
        });
      }
    });

    await GetMemoryIDs('1').then((response) => {
      let selectedMemoryID = '';
      if (response.memories.length > 0) {
        selectedMemoryID = response.memories[0];
      }
      this.setState({
        memories: response.memories,
        selectedMemoryID: selectedMemoryID,
      });
    });

    await GetMemories('1').then((response) => {
      let selectedMemory = '';
      if (response.memories.length > 0) {
        selectedMemory = response.memories[0];
      }
      this.setState({
        memoryData: response.memories,
        selectedMemory: selectedMemory,
      });
    });

    await GetVerseText(1, 1, 1).then((response) => {
      this.setState({
        verseText: response.text,
      });
    });
  }

  componentDidMount() {
    this.initialize().then((response) => {
      this.setState({
        isLoading: false,
      });
    });
  }
  render() {
    return (
      <ThemeProvider theme={backendMaterialTheme}>
        {this.state.isLoading && <i className='fa fa-spinner fa-spin'></i>}
        {!this.state.isLoading && <Styled props={this.state} />}
      </ThemeProvider>
    );
  }
}

export default MemoryEditor;
