import React, { useState } from 'react';
import { ThemeProvider } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ChangeEditor from './ChangeEditor';
import MemoryEditor from './MemoryEditor';
import Manager from './Authentication/Manager';
import { Admin } from './Admin';
import { adminMenuTheme } from './Theme';
import { adminMenuStyles } from './Styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const AdminMenu = () => {
  const classes = adminMenuStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <ThemeProvider theme={adminMenuTheme}>
      <div className={classes.root}>
        <AppBar position='static'>
          <Tabs value={value} onChange={handleChange} aria-label='tsbc admin'>
            <Tab label='Admin' {...a11yProps(0)} />
            <Tab label='User Editor' {...a11yProps(1)} />
            <Tab disabled label='Change Editor (Advanced)' {...a11yProps(2)} />
            <Tab disabled label='Memory Editor (Advanced)' {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Admin />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Manager />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ChangeEditor />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MemoryEditor data='' />
        </TabPanel>
      </div>
    </ThemeProvider>
  );
};
export default AdminMenu;
