import React from 'react';
import sha256 from 'crypto-js/sha256';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Login } from '../../../common/Auth';
import { LoginDialogStyles } from './Styles';

interface LoginDialogProps {
  handleToken: any;
}

export default function LoginDialog(props: LoginDialogProps) {
  const classes = LoginDialogStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(true);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  const handleSubmit = async (event: any) => {
    //console.log(username);
    //console.log(password);
    // call login
    const { status, token, message } = await Login(username, sha256(password).toString());
    if (status) {
      // on success, return token
      props.handleToken(token);
    } else {
      // if auth fails, set message and clear password
      setAlertMessage(`Login Failed: ${message}`);
      setAlertSeverity('error');
      setPassword('');
    }
  };

  const handleCancel = async (event: any) => {
    props.handleToken(null);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Dialog open={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
          </Paper>
        </DialogTitle>
        <DialogContent>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label='Username'
            name='username'
            autoComplete='username'
            autoFocus
            value={username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(event.target.value);
            }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
          />
          <DialogContentText id='alert-dialog-description' className={classes.message}>
            {alertMessage.length > 0 && <Alert severity={alertSeverity}>{alertMessage}</Alert>}
          </DialogContentText>
          <Paper className={classes.paper}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={handleSubmit}>
              Sign In
            </Button>
            <Button
              tabIndex={4}
              type='button'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={handleCancel}>
              Cancel
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setRememberMe(event.target.checked);
                  }}
                  color='primary'
                />
              }
              label='Remember me'
            />
          </Paper>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
