import React from 'react';
import * as Diff from 'diff';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Tooltip from '@mui/material/Tooltip';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';

interface VerseDiffFormatting {
  style: any;
  value: any;
}
type VerseDiffProps = {
  original: string;
  memory: any;
};

/*
type DiffInfo = {
  added: string;
  removed: string;
  unchanged: string;
  formatted: string;
};
*/
export const VerseDiff = (props: VerseDiffProps) => {
  const formatContent = (content: any) => {
    const formattedContent = content.map((whatever: any, i: any) => (
      <span key={i} style={whatever.style}>
        {whatever.value}{' '}
      </span>
    ));
    return formattedContent;
  };
  const getDifference = (original: string, item: any) => {
    try {
      let content: VerseDiffFormatting[] = [];
      let textDiff = Diff.diffWords(item.restoredText, original);
      textDiff.forEach(function (part: any) {
        if (part.added) {
          content.push({
            style: { color: 'red' },
            value: '[' + part.value + ']',
          });
        } else {
          if (part.removed) {
            content.push({
              style: { color: 'green' },
              value: part.value,
            });
          } else {
            content.push({
              style: { color: 'inherit' },
              value: part.value,
            });
          }
        }
      });
      return (
        <ListItem key={Math.random()}>
          <Tooltip title='Difference'>
            <ListItemIcon>
              <CompareArrowsOutlinedIcon color='warning' />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={formatContent(content)} />
        </ListItem>
      );
    } catch (err) {
      console.log(err);
    }
    return <> </>;
  };

  const getNotes = (item: string) => {
    return (
      <ListItem key={Math.random()}>
        <Tooltip title='Notes'>
          <ListItemIcon>
            <SpeakerNotesIcon color='info' />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={item} />
      </ListItem>
    );
  };
  const getOriginal = (item: string) => {
    return (
      <ListItem key={Math.random()}>
        <Tooltip title='Current Verse'>
          <ListItemIcon>
            <CancelOutlinedIcon color='error' />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={item} />
      </ListItem>
    );
  };
  const getMemoryOfVerse = (item: string) => {
    return (
      <ListItem key={Math.random()}>
        <Tooltip title='Memory of Verse'>
          <ListItemIcon>
            <CheckCircleOutlinedIcon color='success' />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={item} />
      </ListItem>
    );
  };
  // only show list if there are either notes or memory text, not just verse by itself
  let showDiff = false;
  if (props.memory.notes.length > 0) {
    showDiff = true;
  }
  if (props.memory.restoredText.length > 0) {
    showDiff = true;
  }
  return (
    <>
      {showDiff && (
        <Paper variant='outlined'>
          <List>
            {getOriginal(props.original)}
            {props.memory.restoredText.length > 0 && getMemoryOfVerse(props.memory.restoredText)}
            {props.memory.restoredText.length > 0 && getDifference(props.original, props.memory)}
            {props.memory.notes.length > 0 && getNotes(props.memory.notes)}
          </List>
        </Paper>
      )}
    </>
  );
};
