import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    title: {
      alignContent: 'center',
    },
    paper: {
      textAlign: 'center',
      background: 'transparent',
    },
  })
);

export interface HealthCheckProps {
  isDown: boolean;
}

export default function HealthCheck(props: HealthCheckProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.isDown && (
        <Alert severity='error'>
          <AlertTitle>Site Under Maintenance</AlertTitle>
          <Container className={classes.paper}>
            <CircularProgress color='secondary' />
          </Container>
        </Alert>
      )}
    </div>
  );
}
