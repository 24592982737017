import { alpha, createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import blueGrey from '@mui/material/colors/blueGrey';
import { withStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';

const appMaterialTheme = createTheme({
  palette: {
    primary: blueGrey,
  },
});

const appStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: 'white',
      display: 'flex',
      height: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      overflow: 'visible !important',
      marginLeft: '15px !important',
      marginRight: '10px !important',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionAdmin: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    footer: {
      backgroundColor: 'white',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: '80ch',
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '400px !important',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchButton: {
      margin: theme.spacing(0, 2),
    },
    inputRoot: {
      color: 'inherit',
    },
    searchAutoComplete: {
      backgroundColor: theme.palette.background.paper,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `${theme.spacing(2)}px`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  })
);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export { appStyles, appMaterialTheme, StyledMenuItem };
