import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import NumericInput from 'react-numeric-input';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { backendStyles } from './Styles';
import { MemoryProps } from './types';

export interface MemoryEditorBCVSelectProps {
  editorSettings: MemoryProps;
  setEditorSettings: any;
}

export const MemoryEditorBCVSelect = (props: MemoryEditorBCVSelectProps) => {
  const classes = backendStyles();
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid item>
        <Autocomplete
          id='book'
          style={{ width: 200 }}
          value={props.editorSettings.bookName}
          onChange={props.editorSettings.onChangeBook}
          inputValue={props.editorSettings.inputBookValue}
          onInputChange={(event, newInputValue) => {
            props.editorSettings.inputBookValue = newInputValue;
            props.setEditorSettings(props.editorSettings);
          }}
          options={props.editorSettings.bookNames}
          renderInput={(params) => <TextField {...params} required label='Book' margin='dense' />}
        />
      </Grid>
      <Grid item className={classes.chapterVerseGrid}>
        <FormControlLabel
          label='Chapter'
          labelPlacement='start'
          control={
            <NumericInput
              mobile
              id='chapter'
              className={classes.chapterVerse}
              aria-label='chapter'
              inputMode='numeric'
              min={1}
              max={66}
              value={props.editorSettings.chapter}
              onChange={props.editorSettings.onChangeChapter}
            />
          }
        />
      </Grid>
      <Grid item className={classes.chapterVerseGrid}>
        <FormControlLabel
          label='Verse'
          labelPlacement='start'
          control={
            <NumericInput
              mobile
              id='verse'
              className={classes.chapterVerse}
              aria-label='verse'
              inputMode='numeric'
              min={1}
              max={60}
              value={props.editorSettings.verse}
              onChange={props.editorSettings.onChangeVerse}
            />
          }
        />
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='stretch'>
        <Grid item>
          <Card>
            <CardContent>
              <Typography align='center'>{props.editorSettings.selectedVerseText}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
