import React from 'react';
import * as Diff from 'diff';

interface Formatting {
  style: any;
  value: any;
}

export const FormatOriginal = (original: string, memory: string) => {
  try {
    let assembled: Formatting[] = [];
    let textDiff = Diff.diffWords(original, memory);
    textDiff.forEach(function (part: any) {
      if (part.added) {
        // do not add
      } else {
        if (part.removed) {
          assembled.push({
            style: { color: 'red' },
            value: part.value,
          });
        } else {
          assembled.push({
            style: { color: 'inherit' },
            value: part.value,
          });
        }
      }
    });
    return (
      <>
        {assembled.map((data: any, i: any) => (
          <span key={i} style={data.style}>
            {data.value}
          </span>
        ))}
      </>
    );
  } catch (err) {
    console.log(err);
  }
  return <> </>;
};

export const FormatMemory = (original: string, memory: string) => {
  try {
    let assembled: Formatting[] = [];
    let textDiff = Diff.diffWords(original, memory);
    textDiff.forEach(function (part: any) {
      if (part.added) {
        assembled.push({
          style: { color: 'green' },
          value: part.value,
        });
      } else {
        if (!part.removed) {
          assembled.push({
            style: { color: 'inherit' },
            value: part.value,
          });
        }
      }
    });
    return (
      <>
        {assembled.map((data: any, i: any) => (
          <span key={i} style={data.style}>
            {data.value}
          </span>
        ))}
      </>
    );
  } catch (err) {
    console.log(err);
  }
  return <> </>;
};
