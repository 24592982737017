import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const frontendStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    card: {
      width: 600,
      alignContent: 'center',
      backgroundColor: 'grey',
      margin: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    chapterVerse: {
      width: 100,
    },
    chapterVerseGrid: {},
    chapterNumericInput: {
      marginRight: '4px',
    },
    counter: {
      width: 120,
      marginLeft: 12,
      marginTop: 20,
    },
    verseViewer: {
      fontSize: '35px',
      textAlign: 'center',
    },
    verseText: {
      fontSize: '35px',
      textAlign: 'center',
    },
    verseHeader: {
      fontSize: '40px',
      textAlign: 'center',
    },
    chapterViewer: {
      fontSize: '14px',
      textAlign: 'left',
    },
    chapterText: {
      fontSize: '14px',
      textAlign: 'left',
    },
    chapterHeader: {
      fontSize: '18px',
      textAlign: 'center',
    },
  })
);

export const residueVerseViewerStyles = makeStyles(() => ({
  verseViewer: {
    fontSize: 18,
    color: '#333',
    textAlign: 'center',
  },
  verseViewerText: {
    fontSize: 22,
    textAlign: 'center',
  },
  verseViewerTitle: {
    fontSize: 26,
    textAlign: 'center',
    letterSpacing: '6px',
  },
}));

export const memorySummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 400,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
    },
    listSection: {
      backgroundColor: theme.palette.background.paper,
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  })
);

export const SummaryStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 'x-large',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 'large',
      textAlign: 'center',
    },
    caption: {
      fontSize: 'x-large',
    },
    verse: {
      fontSize: 'x-large',
    },
    pos: {
      marginBottom: 12,
    },
    media: {
      height: 140,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  })
);
