import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@mui/styles';
import { FrontendMaterialTheme } from '../Theme';
import { searchResultStyles } from './Styles';
import MaterialTable from 'material-table';
import { GetBookNames, Search } from '../../../common/Api';
import { StyledScrollUp } from '../../common/StyledScrollUp';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Emoji from 'react-emoji-render';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

export interface SearchTableProps {
  searchText: string;
}
interface SearchResult {
  Book: number;
  Chapter: number;
  Verse: number;
  BCV: string;
  Text: any;
}

interface InternalProps {
  formatted: any;
  searchText: string;
  isLoading: boolean;
  bookNames: string[];
}
/*
  Shows verses surrounding a specified verse
 */
export const SearchTable = (props: SearchTableProps) => {
  const emojiEnabled = false;

  //console.log(`SearchTable1: ${props.searchText}`);
  const emptyData = { Book: 1, Chapter: 1, Verse: 1, BCV: '', Text: '' } as SearchResult;
  const [previousSearchText, setPreviousSearchText] = useState('');
  // for adjusting page
  const tableRef = useRef<any>();

  const [selectedRow, setSelectedRow] = useState({
    Book: 1,
    Chapter: 1,
    Verse: 1,
    BCV: 'Genesis 1:1',
    Text: 'Genesis',
  } as SearchResult);

  const state: InternalProps = {
    formatted: [emptyData],
    searchText: props.searchText,
    isLoading: true,
    bookNames: [],
  };

  const [tableSettings, setTableSettings] = React.useState<InternalProps>(state);

  const classes = searchResultStyles();

  const getIndicesOf = (searchStr: string, str: string, caseSensitive: boolean) => {
    var searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  const fetchData = async (searchText: string) => {
    let bookNames = tableSettings.bookNames;
    if (tableSettings.bookNames.length === 0) {
      const bookNameData = await GetBookNames();
      tableSettings.bookNames = bookNameData.names;
      bookNames = bookNameData.names;
    }
    //console.log(`loading... new search: ${searchText} prevSearch: ${previousSearchText}`);
    const data = await Search(searchText);
    // slight delay
    await new Promise((r) => setTimeout(r, 250));
    const formatted: SearchResult[] = [];
    for (let i = 0; i < data.length; i++) {
      const bookNumber = data[i].book;
      const bookName = bookNames[bookNumber - 1];
      const bcv = `${bookName} ${data[i].chapter}:${data[i].verse}`;
      formatted.push({
        BCV: bcv,
        Book: data[i].book,
        Chapter: data[i].chapter,
        Verse: data[i].verse,
        Text: data[i].text,
      });
    }
    tableSettings.formatted = formatted;
    tableSettings.isLoading = false;
    tableSettings.searchText = searchText;
    setPreviousSearchText(searchText);
    // only change page if not already there...
    if (tableRef && tableRef.current && tableRef.current.dataManager.currentPage > 0) {
      tableRef.current.onChangePage(null, 0);
    }
    setTableSettings({ ...tableSettings });
  };

  useEffect(() => {
    if (props.searchText !== previousSearchText) {
      fetchData(props.searchText);
    }
  });
  /*
  TODO: no results message
  formatted.push(
    {
      "Book": 1,
      "Chapter": 1,
      "Verse": 1,
      "BCV": 'Genesis 1:1',
      "Text": 'test'
    }
  );
  */

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontendMaterialTheme}>
        {tableSettings.isLoading ? (
          <div className={classes.loading}>Loading ...</div>
        ) : (
          <MaterialTable
            tableRef={tableRef}
            title={`Search Results: ${props.searchText}`}
            data={tableSettings.formatted}
            onRowClick={(evt, selectedRow, togglePanel) => {
              setSelectedRow(selectedRow as SearchResult);
            }}
            style={{
              backgroundColor: '#607d8b',
            }}
            options={{
              paginationPosition: 'both',
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 15, 20, 25, 30, 40, 50, 100],
              search: true,
              searchFieldAlignment: 'left',
              sorting: true,
              headerStyle: {
                backgroundColor: '#01579b', // #3299eb
                color: '#FFF',
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  selectedRow &&
                  selectedRow.Book === rowData.Book &&
                  selectedRow.Chapter === rowData.Chapter &&
                  selectedRow.Verse === rowData.Verse
                    ? '#EEE'
                    : '#FFF',
              }),
            }}
            columns={[
              {
                title: 'BCV',
                field: 'BCV',
                width: '15%',
              },
              {
                title: '',
                field: 'Text',
                width: 'auto',
                headerStyle: { whiteSpace: 'nowrap' },
                cellStyle: { whiteSpace: 'normal' },
                render: (rowData) => {
                  let offSet = 0;
                  const searchTextLength = tableSettings.searchText.length;
                  const verseParts = [];
                  const indexes = getIndicesOf(tableSettings.searchText, rowData.Text, false);
                  for (let i = 0; i < indexes.length; i++) {
                    if (indexes[i] > offSet) {
                      const part = rowData.Text.slice(offSet, indexes[i]);
                      verseParts.push(part);
                      // use the original text but styled
                      const matchedText = rowData.Text.slice(indexes[i], indexes[i] + searchTextLength);
                      let content: JSX.Element = <>{matchedText}</>;
                      if (emojiEnabled) {
                        content = <Emoji text={matchedText} />;
                      }
                      verseParts.push(
                        <span className={classes.matchedText} key={i}>
                          {content}
                        </span>
                      );
                      offSet = indexes[i] + searchTextLength;
                    }
                  }
                  // check for trailing text
                  if (offSet < rowData.Text.length) {
                    const part = rowData.Text.slice(offSet);
                    verseParts.push(part);
                  }
                  return <>{verseParts}</>;
                },
              },
            ]}
          />
        )}
        <StyledScrollUp
          startPosition={0}
          showAtPosition={10}
          position='right'
          className='scroll-up'
          style={{
            left: '90px',
          }}>
          <Button variant='contained' size='large' color='secondary'>
            <ArrowUpwardIcon color='secondary' />
          </Button>
        </StyledScrollUp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default SearchTable;
