import React from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// scribe
import { VerseDiff } from './VerseDiff';
import { Tooltip } from '@mui/material';
import { memorySummaryStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';

type MemoryProps = {
  data: any;
};

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
    mode: 'light',
  },
});

export const MemorySummary = (props: MemoryProps) => {
  const [openMemoryModal, setOpenMemoryModal] = React.useState(false);
  const handleOpenMemoryModal = () => {
    setOpenMemoryModal(true);
  };
  const handleCloseMemoryModal = () => {
    setOpenMemoryModal(false);
  };
  const createMemoryContent = (item: string) => {
    return (
      <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }} key={uuidv4()}>
        <ListItemIcon>
          <Tooltip title='Memory of Verse Available, click icon to view'>
            <IconButton onClick={handleOpenMemoryModal} aria-label='show-memories-dialog' color='success'>
              <ImportContactsIcon fontSize='large' />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    );
  };
  const createNotes = (item: string) => {
    return (
      <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }} key={uuidv4()}>
        <ListItemIcon>
          <Tooltip title='Notes Available, click icon to view'>
            <IconButton onClick={handleOpenMemoryModal} aria-label='show-notes-dialog' color='info'>
              <SpeakerNotesIcon fontSize='large' />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    );
  };

  const getListItem = (item: any) => {
    if (item.notes.length === 0 && item.restoredText.length === 0) {
      // skip showing this one, likely only residue upload (must return a unique item with a key)
      return <div key={uuidv4()}></div>;
    }
    return (
      <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }} key={`item-${item.ID}`}>
        {haveMemoryOfVerse ? (
          <Tooltip title='Memory of Verse'>
            <ListItemIcon>
              <ImportContactsIcon fontSize='large' color='success' />
            </ListItemIcon>
          </Tooltip>
        ) : (
          <Tooltip title='Commentary'>
            <ListItemIcon>
              <SpeakerNotesIcon fontSize='large' color='info' />
            </ListItemIcon>
          </Tooltip>
        )}
        <ListItemText>
          <VerseDiff original={props.data.currentVerse} memory={item} />
        </ListItemText>
      </ListItem>
    );
  };

  const classes = memorySummaryStyles();
  let haveMemoryOfVerse = false;
  let notesContent: string[] = [];
  let haveNotesContent = false;

  // show the change notes first
  if (props.data.notes !== '') {
    notesContent.push(props.data.notes);
  }
  // show summary notes from "best memory"
  if (props.data.memorySummary.notes.length > 0) {
    // skip duplicates
    if (props.data.notes !== props.data.memorySummary.notes) {
      notesContent.push(props.data.memorySummary.notes);
    }
  }
  if (notesContent.length > 0) {
    haveNotesContent = true;
  }

  let memoryContent = '';
  if (
    props.data.memorySummary.restoredText.length > 0 &&
    props.data.currentVerse !== props.data.memorySummary.restoredText
  ) {
    haveMemoryOfVerse = true;
    memoryContent = props.data.memorySummary.restoredText;
  }
  if (props.data.MemoriesOfVerse) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <List sx={{ paddingTop: '0px', paddingBottom: '0px' }} dense={true}>
            {haveMemoryOfVerse && createMemoryContent(memoryContent)}
            {haveNotesContent && notesContent.map(createNotes)}
          </List>
          <Dialog
            maxWidth='md'
            open={openMemoryModal}
            onClose={handleCloseMemoryModal}
            scroll='paper'
            aria-labelledby='memory-modal-title'
            aria-describedby='memory-modal-description'>
            <DialogTitle id='scroll-dialog-title'>Memories of {props.data.BCV}</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText component='span' id='scroll-dialog-description' tabIndex={-1}>
                <List dense={true} className={classes.root} subheader={<li />}>
                  {props.data.MemoriesOfVerse.map((item: any) => getListItem(item))}
                </List>
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleCloseMemoryModal} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
  return <> </>;
};
