import React, { useEffect } from 'react';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import ShareIcon from '@mui/icons-material/Share';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
// Scribe
import { GetChanges, GetMemoriesWithResidue } from '../../common/Api';
import { ResidueVerseViewer } from './ResidueVerseViewer';
import { ResidueViewerDialog } from './ResidueViewerDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    cardActions: {
      backgroundColor: '#607d8b',
      justifyContent: 'center',
      padding: '0px',
    },
    avatar: {
      fontSize: 'large',
      color: theme.palette.primary.main,
    },

    paper: {
      textAlign: 'center',
    },
    summaryText: {
      fontSize: theme.typography.pxToRem(18) + '!important',
      textAlign: 'center',
      marginBottom: '5px',
      marginLeft: '5px',
    },
    residueNotes: {
      fontSize: theme.typography.pxToRem(15) + '!important',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    detailNotes: {
      width: '100%',
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    memoryOfVerseHeader: {
      backgroundColor: '#607d8b',
      padding: '5px',
    },
    memoryOfVerseCardContent: {
      backgroundColor: '#607d8b',
      padding: '0px',
    },
    expand: {
      fontSize: theme.typography.pxToRem(40) + '!important',
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      fontSize: theme.typography.pxToRem(40) + '!important',
      transform: 'rotate(180deg)',
    },
    gridList: {
      padding: '16px',
    },
    cardActionsPaper: {
      display: 'inline-flex',
      width: '97%',
      alignItems: 'center',
    },
    dialogToolbar: {
      padding: '16px',
    },
  })
);

interface TileProps {
  ID: number;
  imagePath: string;
  imageDate: string;
  notes: string;
}
export const ResidueViewerDetailed = (props: any) => {
  const classes = useStyles();
  const [selectedTile, setSelectedTile] = React.useState<TileProps>({ ID: 0, imageDate: '', imagePath: '', notes: '' });
  const [isLoading, setIsLoading] = React.useState(true);
  const [memoryWithResidueData, setMemoryWithResidueData] = React.useState([]);
  const [tileSize, setTileSize] = React.useState(1);
  const [changeNote, setChangeNote] = React.useState<string[]>([]);
  const [expanded, setExpanded] = React.useState(true);
  const [changeID, setChangeID] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const fetchData = async (bookNumber: number, chapterNumber: number, verseNumber: number) => {
    const cData = await GetChanges(bookNumber, chapterNumber, verseNumber).then((response) => {
      if (response && response.length > 0) {
        return response[0];
      }
    });
    if (cData && cData.ID) {
      const allNotes = [];
      const changeID = cData.ID;
      setChangeID(changeID);
      // get notes of the change
      if (cData.notes.length > 0) {
        setChangeNote(cData.notes);
      }
      const mData = await GetMemoriesWithResidue(changeID).then((response) => {
        if (response.memories.length > 0) {
          return response.memories;
        }
      });
      // for memories without residue images, add the stock photo as a placeholder
      for (let i = 0; i < mData.length; i++) {
        if (mData[i].residueImages === null) {
          mData[i].residueImages = [
            {
              imagePath: 'img/bible_changes.jpg',
              imageURL: 'img/bible_changes.jpg',
              imageDate: mData[i].memoryDate,
              notes: mData[i].notes,
            },
          ];
        }
      }
      setMemoryWithResidueData(mData);
      // determine tile size, if there's less than two residue image, set it to 2, otherwise 1
      let numImages = 0;
      for (let i = 0; i < mData.length; i++) {
        // there may be memories with no residue
        // this should not happen with the placeholders added above
        if (mData[i].residueImages) {
          numImages += mData[i].residueImages.length;
        }
        if (mData[i].notes.length > 0) {
          allNotes.push(mData[i].notes);
        }
      }
      if (numImages < 2) {
        setTileSize(2);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (isLoading) {
      fetchData(
        props.data.book || props.chapterViewerProps.bookNumber,
        props.data.chapter || props.chapterViewerProps.chapterNumber,
        props.data.verse || props.data.Verse
      );
    }
  });

  const getClipboardData = (): string => {
    return `${window.location.origin}/changes/${changeID}`;
  };

  const copyToClipBoardAction = () => {
    copy(getClipboardData());
    setAlertMessage('Link Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const getClipboardDataForDiscord = (): string => {
    return `${window.location.origin}/changeDetail/${changeID}`;
  };

  const copyToClipBoardForDiscordAction = () => {
    copy(getClipboardDataForDiscord());
    setAlertMessage('Detail Link Copied to Clipboard');
    setAlertSeverity('success');
    setShowAlert(true);
  };

  const handleClickOpen = (tile: any) => {
    setSelectedTile(tile);
  };
  const handleClose = () => {
    setSelectedTile({ ID: 0, imageDate: '', imagePath: '', notes: '' });
  };

  const getBCV = () => {
    if (props.chapterViewerProps?.bookName) {
      return `${props.chapterViewerProps.bookName} ${props.chapterViewerProps.chapterNumber}:${props.data.Verse}`;
    }
    return props.data.BCV;
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          className={classes.memoryOfVerseHeader}
          avatar={
            <>
              <Tooltip title='Copy Link'>
                <IconButton
                  aria-label='residueviewer-copy-to-clipboard'
                  onClick={copyToClipBoardAction}
                  className={classes.avatar}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Detail Link'>
                <IconButton
                  aria-label='residueviewer-discord-copy-link'
                  onClick={() => {
                    copyToClipBoardForDiscordAction();
                  }}>
                  <IosShareOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          }
          title={`Memory of ` + getBCV()}
          subheader={`Change ID: ${changeID}`}
        />
        <CardContent className={classes.memoryOfVerseCardContent}>
          <Paper className={classes.paper} style={{ alignSelf: 'center', width: '100%' }}>
            <ResidueVerseViewer
              bookName={props.data.bookName || props.chapterViewerProps.bookName}
              chapterNumber={props.data.chapter || props.chapterViewerProps.chapterNumber}
              verseNumber={props.data.verse || props.data.Verse}
              verseText={props.data.memorySummary?.restoredText || props.data.memoryOfVerse}
            />
            {changeNote.length > 0 && (
              <Typography className={classes.residueNotes}>Change Note: {changeNote}</Typography>
            )}
          </Paper>
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          <Typography className={classes.summaryText}>Residue for {getBCV()}</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'>
            <ArrowDropDownCircleOutlinedIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <ImageList className={classes.gridList} cols={2}>
            {memoryWithResidueData &&
              memoryWithResidueData.map(
                (memory: any) =>
                  memory.residueImages &&
                  memory.residueImages.map((tile: any) => (
                    <ImageListItem key={Math.random()} cols={tileSize}>
                      <img src={tile.imagePath} alt={tile.notes} onClick={() => handleClickOpen(tile)} />
                      <ImageListItemBar
                        position='bottom'
                        actionPosition='left'
                        title={memory.restoredText || memory.notes}
                        subtitle={<span>{memory.memoryDate}</span>}
                        actionIcon={
                          <IconButton
                            aria-label={`Enlarge ${tile.id}`}
                            className={classes.icon}
                            value={tile.id}
                            onClick={() => handleClickOpen(tile)}>
                            <InfoIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))
              )}
          </ImageList>
        </Collapse>
      </Card>
      <ResidueViewerDialog selectedTile={selectedTile} changeID={changeID} handleClose={handleClose} />
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} variant='filled' severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
