import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { StyledScrollUp } from '../common/StyledScrollUp';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import { adminStyles } from './Styles';
import { adminMaterialTheme } from './Theme';
import {
  GetChanges,
  GetBookNames,
  GetVerseText,
  GetChapterVerses,
  GetChaptersOfBook,
  GetMemories,
} from '../../common/Api';
import { GetBookNumber } from './Utils';
import { BCVSelect } from '../common/BCVSelect';
import { ConfirmationDialog } from '../common/ConfirmationDialog';
import { AdminProps } from './types';
import { VerseViewer } from '../frontend/BibleReader/VerseViewer';
import { AddChangeDialog } from './AddChangeDialog';
import { EditChangeDialog } from './EditChangeDialog';
import { AddMemoryDialog } from './AddMemoryDialog';
import { EditMemoryDialog } from './EditMemoryDialog';
import { MemoryTable } from './MemoryTable';
import { Delay } from './Utils';
import { DeleteChange, DeleteMemory } from '../../common/Api';
import { MemoryResponse } from './types';

export const Admin = (props: any) => {
  const classes = adminStyles();
  const [showAlert, setShowAlert] = React.useState(false);
  const [editMemoryDisabled, setEditMemoryDisabled] = React.useState(true);
  const [memorySelected, setMemorySelected] = React.useState<MemoryResponse | null>(null);
  const [showDeleteChangeConfirmationDialog, setShowDeleteChangeConfirmationDialog] = React.useState(false);
  const [showDeleteMemoryConfirmationDialog, setShowDeleteMemoryConfirmationDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');

  const onChangeBook = async (event: any) => {
    if (event) {
      const bookName = event.target.textContent;
      editorSettings.book = bookName;
      const b = GetBookNumber(editorSettings.bookNames, bookName);
      editorSettings.bookNumber = b;
      editorSettings.inputBookValue = bookName;
      await GetChaptersOfBook(b).then((response) => {
        editorSettings.bookMaxChapters = response.chapters;
      });
      await GetChapterVerses(b, 1).then((response) => {
        editorSettings.chapterMaxVerses = response.verses;
      });
      setEditorSettings({ ...editorSettings });
      // reset to chapter 1 to stay in range
      onChangeChapter(1);
    }
  };
  const onChangeChapter = async (value: any) => {
    if (value > 0) {
      editorSettings.chapter = value;
      await GetChapterVerses(editorSettings.bookNumber, value).then((response) => {
        editorSettings.chapterMaxVerses = response.verses;
      });
      setEditorSettings({ ...editorSettings });
      // reset to verse 1 to stay in range
      onChangeVerse(1);
    }
  };
  const onChangeVerse = async (value: any) => {
    if (value > 0) {
      editorSettings.verse = value;
      onVerseSelectChanged(value);
      await GetChanges(editorSettings.bookNumber, editorSettings.chapter, value).then((response) => {
        if (response && response.length > 0) {
          editorSettings.changeExists = true;
          editorSettings.changeData = response[0];
        } else {
          editorSettings.changeExists = false;
          editorSettings.changeData = null;
        }
      });
      if (editorSettings && editorSettings.changeData) {
        await GetMemories(editorSettings.changeData.ID).then((response) => {
          editorSettings.memoryData = response.memories;
        });
      } else {
        editorSettings.memoryData = [];
      }
      setEditMemoryDisabled(true);
      setEditorSettings({ ...editorSettings });
    }
  };
  const onVerseSelectChanged = async (verse: any) => {
    const response = await GetVerseText(editorSettings.bookNumber, editorSettings.chapter, editorSettings.verse);
    editorSettings.selectedVerseText = response.text;
    setEditorSettings({ ...editorSettings });
  };

  const onAddChangeDialogClosed = async () => {
    editorSettings.showAddChangeDialog = false;
    setEditorSettings({ ...editorSettings });
    await Delay(2000);
    // force reload
    onChangeVerse(editorSettings.verse);
  };

  const onEditChangeDialogClosed = async () => {
    editorSettings.showEditChangeDialog = false;
    setEditorSettings({ ...editorSettings });
    await Delay(2000);
    // force reload
    onChangeVerse(editorSettings.verse);
  };

  const onAddMemoryDialogClosed = async () => {
    editorSettings.showAddMemoryDialog = false;
    setEditorSettings({ ...editorSettings });
    await Delay(2000);
    // force reload
    onChangeVerse(editorSettings.verse);
  };

  const onEditMemoryDialogClosed = async () => {
    editorSettings.showEditMemoryDialog = false;
    setEditorSettings({ ...editorSettings });
    // force reload
    await Delay(2000);
    onChangeVerse(editorSettings.verse);
  };

  const initProps: AdminProps = {
    book: 'Genesis',
    bookNames: [],
    bookNumber: 1,
    chapter: 1,
    verse: 1,
    bookMaxChapters: 33,
    inputBookValue: 'Genesis',
    changeExists: false,
    changeData: null,
    memoryData: null,
    selectedDate: new Date(),
    selectedVerseText: '',
    chapterMaxVerses: 30,
    onChangeChapter: onChangeChapter,
    onChangeVerse: onChangeVerse,
    onChangeBook: onChangeBook,
    isLoading: true,
    showAddChangeDialog: false,
    addChangeDialogClosed: onAddChangeDialogClosed,
    showEditChangeDialog: false,
    editChangeDialogClosed: onEditChangeDialogClosed,
    showAddMemoryDialog: false,
    addMemoryDialogClosed: onAddMemoryDialogClosed,
    showEditMemoryDialog: false,
    editMemoryDialogClosed: onEditMemoryDialogClosed,
    editMemoryDisabled: true,
    selectedMemoryID: 0,
  };
  const [editorSettings, setEditorSettings] = React.useState<AdminProps>(initProps);

  const fetchData = async (searchText: string) => {
    if (editorSettings.bookNames.length === 0) {
      const bookNameData = await GetBookNames();
      editorSettings.bookNames = bookNameData.names;
      editorSettings.isLoading = false;
      // start off getting the change for the current bcv
      await GetChanges(1, 1, 1).then((response) => {
        if (response && response.length > 0) {
          editorSettings.changeExists = true;
          editorSettings.changeData = response[0];
        } else {
          editorSettings.changeExists = false;
          editorSettings.changeData = null;
        }
      });
      await GetMemories(editorSettings.changeData.ID).then((response) => {
        editorSettings.memoryData = response.memories;
      });
    }
    if (editorSettings.selectedVerseText.length === 0) {
      // need to get maxChapters and maxVerse of current chapter
      await GetChapterVerses(editorSettings.bookNumber, editorSettings.chapter).then((response) => {
        editorSettings.chapterMaxVerses = response.verses;
      });
      await GetChaptersOfBook(editorSettings.bookNumber).then((response) => {
        editorSettings.bookMaxChapters = response.chapters;
      });
      onChangeVerse(editorSettings.verse);
    }
    setEditorSettings({ ...editorSettings });
  };
  useEffect(() => {
    if (editorSettings.bookNames.length === 0) {
      // load book names
      fetchData(props.searchText);
    }
  });

  const onAddChange = async () => {
    editorSettings.showAddChangeDialog = true;
    setEditorSettings({ ...editorSettings });
  };
  const onEditChange = async () => {
    editorSettings.showEditChangeDialog = true;
    setEditorSettings({ ...editorSettings });
  };

  const confirmDeleteChange = () => {
    setShowDeleteChangeConfirmationDialog(true);
  };
  const onDeleteChange = async () => {
    const payload = {
      id: parseInt(editorSettings.changeData.ID, 10),
    };
    const data = await DeleteChange(payload);
    // set alert with status of save
    setAlertMessage('Delete Status: ' + data.message);
    setAlertSeverity('success');
    setShowAlert(true);
    // refresh
    onChangeVerse(editorSettings.verse);
  };

  const onAddMemory = async () => {
    editorSettings.showAddMemoryDialog = true;
    setEditorSettings({ ...editorSettings });
  };
  const onEditMemory = async () => {
    editorSettings.showEditMemoryDialog = true;
    setEditorSettings({ ...editorSettings });
  };
  const confirmDeleteMemory = () => {
    setShowDeleteMemoryConfirmationDialog(true);
  };

  const onDeleteMemory = async () => {
    if (memorySelected === null) {
      return;
    }
    const payload = {
      id: memorySelected.ID,
    };
    const data = await DeleteMemory(payload);
    //console.log(data.message);
    //console.log(data.status);
    // set alert with status of save
    setAlertMessage('Delete Status: ' + data.message);
    setAlertSeverity('success');
    setShowAlert(true);
    // refresh
    onChangeVerse(editorSettings.verse);
  };

  const handleMemorySelected = async (selected: any) => {
    //console.log("memory selected: " + selected)
    setEditMemoryDisabled(false);
    setMemorySelected(selected);
  };

  const ChangeOptions = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography className={classes.instructions}>Database has no change recorded for this verse.</Typography>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => {
            onAddChange();
          }}>
          Add Change
        </Button>
      </div>
    );
  };
  const MemoryOptions = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography className={classes.instructions}>
          Edit or delete the top level change with the next two buttons.
        </Typography>
        <Typography className={classes.instructions}>
          WARNING: Deleting the change will also delete all of the memories associated with it!
        </Typography>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<EditOutlinedIcon />}
          onClick={() => {
            onEditChange();
          }}>
          Edit Change
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => {
            confirmDeleteChange();
          }}>
          Delete Change
        </Button>
        <ConfirmationDialog
          title='WARNING: CHANGE WILL BE DELETED PERMANENTLY'
          text='Are you sure you want to delete this change and all memories associated with it?'
          handleYes={onDeleteChange}
          handleNo={() => {
            setShowDeleteChangeConfirmationDialog(false);
          }}
          open={showDeleteChangeConfirmationDialog}
        />
        <Typography className={classes.instructions}>
          Select a memory in the table below to edit or delete it, or use the "ADD MEMORY" button to add a new entry
        </Typography>
        <MemoryTable
          bookNumber={editorSettings.bookNumber}
          chapterNumber={editorSettings.chapter}
          verseNumber={editorSettings.verse}
          handleMemorySelected={handleMemorySelected}
          memorySelected={memorySelected}
          memoryData={editorSettings.memoryData}
        />
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => {
            onAddMemory();
          }}>
          Add Memory
        </Button>
        <Button
          disabled={editMemoryDisabled}
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<EditOutlinedIcon />}
          onClick={() => {
            onEditMemory();
          }}>
          Edit Memory
        </Button>
        <Button
          disabled={editMemoryDisabled}
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => {
            confirmDeleteMemory();
          }}>
          Delete Memory
        </Button>
        <ConfirmationDialog
          title='WARNING: MEMORY WILL BE DELETED PERMANENTLY'
          text='Are you sure you want to delete this memory?'
          handleYes={onDeleteMemory}
          handleNo={() => {
            setShowDeleteMemoryConfirmationDialog(false);
          }}
          open={showDeleteMemoryConfirmationDialog}
        />
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={adminMaterialTheme}>
        {editorSettings.isLoading && <i className='fa fa-spinner fa-spin'></i>}
        {!editorSettings.isLoading && (
          <>
            <BCVSelect editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
            <VerseViewer
              bookName={editorSettings.book || ''}
              chapterNumber={editorSettings.chapter}
              verseNumber={editorSettings.verse}
              verseText={editorSettings.selectedVerseText}
            />
            {editorSettings.changeExists ? <MemoryOptions /> : <ChangeOptions />}
            {editorSettings.showEditChangeDialog && (
              <EditChangeDialog
                onEditChangeDialogClosed={editorSettings.editChangeDialogClosed}
                bookNumber={editorSettings.bookNumber}
                chapterNumber={editorSettings.chapter}
                verseNumber={editorSettings.verse}
                changeData={editorSettings.changeData}
              />
            )}
            {editorSettings.showAddChangeDialog && (
              <AddChangeDialog
                onAddChangeDialogClosed={editorSettings.addChangeDialogClosed}
                bookNumber={editorSettings.bookNumber}
                chapterNumber={editorSettings.chapter}
                verseNumber={editorSettings.verse}
              />
            )}
            {editorSettings.showAddMemoryDialog && (
              <AddMemoryDialog
                onAddMemoryDialogClosed={editorSettings.addMemoryDialogClosed}
                bookNumber={editorSettings.bookNumber}
                chapterNumber={editorSettings.chapter}
                verseNumber={editorSettings.verse}
                changeData={editorSettings.changeData}
              />
            )}
            {editorSettings.showEditMemoryDialog && (
              <EditMemoryDialog
                onEditMemoryDialogClosed={editorSettings.editMemoryDialogClosed}
                bookNumber={editorSettings.bookNumber}
                chapterNumber={editorSettings.chapter}
                verseNumber={editorSettings.verse}
                memoryData={memorySelected}
              />
            )}
            {showAlert && (
              <Alert variant='filled' severity={alertSeverity}>
                {alertMessage}
              </Alert>
            )}
          </>
        )}
        <StyledScrollUp
          startPosition={0}
          showAtPosition={10}
          position='right'
          className='scroll-up'
          style={{
            left: '90px',
          }}>
          <Button variant='contained' size='large' color='secondary'>
            <ArrowUpwardIcon color='secondary' />
          </Button>
        </StyledScrollUp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
