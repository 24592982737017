import React from 'react';
// MaterialUI
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
// Scribe
import { changeDialogStyles } from './Styles';
import { BackendDatePicker } from './BackendDatePicker';
import { UpdateChange } from '../../common/Api';

import { EditChangeDialogProps } from './types';

function getSteps() {
  return ['Date of Change', 'Comments', 'Finished'];
}

export function EditChangeDialog(props: any) {
  const classes = changeDialogStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [saveDisabled, setSaveDisabled] = React.useState(true);

  const initProps: EditChangeDialogProps = {
    selectedDate: props.changeData.detectedAt,
    notes: props.changeData.notes,
    onEditChangeDialogClosed: props.onEditChangeDialogClosed,
    bookNumber: props.bookNumber,
    chapterNumber: props.chapterNumber,
    verseNumber: props.verseNumber,
    changeNumber: props.changeData.ID || 'TBD',
    changeType: 'Other',
    alertMessage: '',
    alertSeverity: 'info',
    showAlert: false,
  };
  const [editorSettings, setEditorSettings] = React.useState<EditChangeDialogProps>(initProps);

  const onSave = async () => {
    setSaveDisabled(true);
    // convert name of book to number
    const payload = {
      detectedAt: editorSettings.selectedDate,
      source: '',
      notes: editorSettings.notes,
      changeType: editorSettings.changeType,
      keywords: '',
      isMissing: false,
      isHeresy: false,
      isMeaningChange: false,
      isDoctrineChange: false,
      hasFlipflops: false,
      isDuplicate: false,
      isNew: false,
      capitalizationGeneral: false,
      capitalizationHonorifics: false,
      hasPunctuationErrors: false,
      hasGrammarErrors: false,
      wordChangeCount: 0,
      grammarErrorCount: 0,
      punctuationErrorCount: 0,
      upVotes: 0,
      downVotes: 0,
      book: editorSettings.bookNumber,
      chapter: editorSettings.chapterNumber,
      verse: editorSettings.verseNumber,
      ID: parseInt(editorSettings.changeNumber, 10),
    };
    // post the change, and return the ID with confirmation dialog
    var changeData = await UpdateChange(payload);
    //console.log(changeData.id);
    //console.log("Change ID Updated " + changeData.id);
    //console.log(changeData.message);
    //console.log(changeData.status);
    if (changeData.hasOwnProperty('id')) {
      editorSettings.changeNumber = changeData.id;
    }
    // set alert with status of save
    editorSettings.alertMessage = 'Save Status: ' + changeData.message;
    editorSettings.alertSeverity = 'success';
    editorSettings.showAlert = true;
    setEditorSettings({ ...editorSettings });
  };

  const steps = getSteps();
  const totalSteps = () => {
    return getSteps().length;
  };

  const handleSave = async () => {
    return await onSave();
  };
  const handleClose = () => {
    //setEditorSettings({ ...editorSettings });
    props.onEditChangeDialogClosed();
  };

  const isStepOptional = (step: number) => {
    switch (step) {
      case 1:
        return true;
      case 3:
        return true;
      default:
        return false;
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    if (completedSteps() === totalSteps() - skippedSteps()) {
      if (saveDisabled) {
        setSaveDisabled(false);
      }
    }
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);
    if (completed.size !== totalSteps() - skippedSteps()) {
      if (isLastStep()) {
        // on the last step, enable the save button and do not advance to a skipped step
        setSaveDisabled(false);
      } else {
        // advance to next step
        handleNext();
      }
    } else {
      setSaveDisabled(false);
    }
  };

  const handleReset = () => {
    editorSettings.notes = props.changeData.notes;
    setSaveDisabled(true);
    setEditorSettings({ ...editorSettings });
    setActiveStep(0);
    setCompleted(new Set<number>());
    setSkipped(new Set<number>());
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  return (
    <div>
      <Dialog classes={{ paper: classes.dialogPaper }} open onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Add Change and Residue</DialogTitle>
        <DialogContent>
          <DialogContentText>This will guide you through the process of editing a change</DialogContentText>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const buttonProps: { optional?: React.ReactNode } = {};
                if (isStepOptional(index)) {
                  buttonProps.optional = <Typography variant='caption'>Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} completed={isStepComplete(index)} {...stepProps}>
                    <StepButton onClick={handleStep(index)} {...buttonProps}>
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {activeStep === 0 && (
            <Container>
              <Paper elevation={3}>
                <BackendDatePicker editorSettings={editorSettings} setEditorSettings={setEditorSettings} />
              </Paper>
            </Container>
          )}
          {activeStep === 1 && (
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                editorSettings.notes = event.target.value;
                setEditorSettings({ ...editorSettings });
              }}
              multiline
              rows='4'
              value={editorSettings.notes}
              autoFocus
              margin='dense'
              id='notes'
              label='Comments about verse'
              fullWidth
            />
          )}
          {allStepsCompleted() ? (
            <Alert severity='info'>
              <AlertTitle>Ready to Save</AlertTitle>
              All required steps completed, ready to save!
            </Alert>
          ) : (
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                color='primary'
                variant='contained'
                className={classes.button}>
                Back
              </Button>
              <Button variant='contained' color='primary' onClick={handleNext} className={classes.button}>
                Next
              </Button>
              {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button variant='contained' color='primary' onClick={handleSkip} className={classes.button}>
                  Skip
                </Button>
              )}
              {activeStep !== steps.length &&
                (completed.has(activeStep) ? (
                  <Typography variant='caption' className={classes.completed}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button variant='contained' color='primary' onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                  </Button>
                ))}
            </div>
          )}
          <Snackbar open={editorSettings.showAlert} autoHideDuration={5000} onClose={handleClose}>
            <Alert
              variant='filled'
              severity={editorSettings.alertSeverity}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    handleClose();
                  }}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }>
              {editorSettings.alertMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color='primary'>
            Reset
          </Button>
          <Button onClick={handleSave} disabled={saveDisabled} color='primary'>
            Save
          </Button>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
